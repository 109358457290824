import React, { useContext, useState, useEffect, useCallback } from "react";
import {
  signInWithPopup,
  updatePassword,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, db, provider } from "../firebase";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useGetDocs from "../hooks/useGetDocs";
import { staffObjAtom, currentStaffAtom, staffFromAPIAtom } from "../recoil/atoms";
import { doc, updateDoc } from "firebase/firestore";
import { validateLoginEmail } from "../libraries/functions";
import { COLLECTIONS } from "../libraries/objectsAndArrays";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [loginType, setLoginType] = useState("caregiver");
  const [loginError, setLoginError] = useState();
  const [resetSuccess, setResetSuccess] = useState();
  const staffObj = useRecoilValue(staffObjAtom);
  const staffFromAPI = useRecoilValue(staffFromAPIAtom);
  const setCurrentStaff = useSetRecoilState(currentStaffAtom);
  const [loggedIn, setLoggedIn] = useState(false);
  const { sendRequest: getDocs } = useGetDocs();

  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });

  const signInWithGoogle = () => {
    setLoading(true);
    signInWithPopup(auth, provider)
      .then(async ({ user }) => {
        console.log(staffObj[user.email]);
        if (!staffObj[user.email]) {
          setLoginError(
            "It looks like you used Staff Login for a caregiver email, we've switched you over to the caregiver login page. Please fill out your login information below or reset your password."
          );
          console.log("Not found in staff object");
          logout();
          return;
        }
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/popup-blocked":
            console.log("The pop up was blocked");
            setLoading(false);
            break;
          case "auth/popup-closed-by-user":
            setLoading(false);
            break;
          default:
            console.log(error.code);
        }
      });
  };

  const createUser = async (email, password) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    setCurrentUser(userCredential.user);
    return userCredential.user;
  };

  const signUserIn = (e) => {
    e.preventDefault();
    setLoginError(null);
    setResetSuccess(null);
    signInWithEmailAndPassword(auth, loginCredentials.email, loginCredentials.password)
      .then((userCredential) => {
        setCurrentUser(userCredential.user);
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/wrong-password":
            setLoginError(
              'Incorrect password, please try again or using the link below, reset your password by clicking "Forgot Password?"'
            );
            break;
          case "auth/user-not-found":
            setLoginError(
              'We do not have a user with that email. Using the link below, click "Don\'t have an account? Submit an Application to Get Started" '
            );
            break;
          default:
            setLoginError(`Something Went Wrong, Please Try Again Error Code ${error.code}`);
        }
        logout();
      });
    setLoginCredentials({ email: "", password: "" });
  };

  const resetPassword = () => {
    setLoginError(null);
    console.log(loginCredentials);
    const emailValidate = validateLoginEmail(loginCredentials.email);
    if (!emailValidate) {
      setLoginError("Please Enter a Valid Email Address Above");
    }
    sendPasswordResetEmail(auth, loginCredentials.email)
      .then(() => {
        setResetSuccess(
          `An email with instructions for how to reset your password was just sent to ${loginCredentials.email}. Please follow those instructions to reset your password. `
        );
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          setLoginError("We Do Not Have a User With That Email");
        } else if (error.code === "auth/missing-email") {
          setLoginError(
            "Please enter your email in the email field below and then click Forgot Password"
          );
        } else {
          setLoginError("Something Went Wrong, Please Try Again");
        }
      });
  };

  const updateUserPassword = async (newPassword) => {
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, "TempPassword");
    // Now you can use that to reauthenticate
    reauthenticateWithCredential(user, credential);
    updatePassword(user, newPassword)
      .then(() => {
        console.log(currentUser.id);
        const caregiverRef = doc(db, COLLECTIONS.caregivers, currentUser.id);
        updateDoc(caregiverRef, {
          tempPassword: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const logout = useCallback(() => {
    const unsubscribe = auth.signOut();
    setCurrentUser(null);
    setLoginType("caregiver");
    setLoading(false);
    setCurrentStaff(null);
    setLoggedIn(false);
    return unsubscribe;
  }, [setCurrentStaff, setLoggedIn]);

  useEffect(() => {
    if (!staffFromAPI || !staffObj) return;
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        logout();
        setLoggedIn(false);
        return;
      }
      setLoggedIn(true);
      const caregiverLogin = user.providerData.filter((pd) => pd.providerId === "password");
      const staffLogin = user.providerData.filter((pd) => pd.providerId === "google.com");

      if (staffLogin.length > 0) {
        const [currentStaffFromAPI] = staffFromAPI.filter((s) => s.id === user.email);
        setCurrentUser({
          sitePermissions: staffObj[user.email].sitePermissions,
          access: "staff",
          ...user,
        });
        setCurrentStaff({
          ...currentStaffFromAPI,
          photoURL: user.photoURL,
          ...staffObj[user.email],
        });
      } else if (caregiverLogin) {
        const [matchingCaregiversArray] = await getDocs({
          col: COLLECTIONS.caregivers,
          config: { where: ["email", "==", user.email] },
        });
        if (matchingCaregiversArray.length === 0) {
          console.log("No User Found with that Email");
          setLoginError(
            "There was an error locating your account, please email ryan@thegatheringplacek12.org for help logging in."
          );
          logout();
        } else if (matchingCaregiversArray.length > 1) {
          console.log("There were multiple users with that email found");
          setLoginError(
            "There was an error locating your account, please email ryan@thegatheringplacek12.org for help logging in."
          );
          logout();
        } else {
          console.log("There was one user found");
          setCurrentUser({ access: "caregiver", ...user, ...matchingCaregiversArray[0] });
        }
      } else {
        logout();
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [staffFromAPI, setCurrentStaff, staffObj, logout, getDocs]);

  useEffect(() => {
    if (currentUser) {
      setLoading(false);
    }
  }, [currentUser]);

  const value = {
    currentUser,
    setCurrentUser,
    signInWithGoogle,
    logout,
    createUser,
    signUserIn,
    updateUserPassword,
    loginType,
    setLoginType,
    loginCredentials,
    setLoginCredentials,
    setLoginError,
    loginError,
    resetPassword,
    resetSuccess,
    loggedIn,
    loading,
    setResetSuccess,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
