import React from "react";
import {
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions,
  Dialog,
} from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { appsResetAtom } from "../../recoil/atoms";
import { getNewLastCommentDate } from "../../libraries/functions";
import { useSetRecoilState } from "recoil";
import { COLLECTIONS } from "../../libraries/objectsAndArrays";

export default function DeleteCommentDialog({
  open,
  setOpen,
  selectedComment,
  setResetComments,
  comments,
}) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setAppsReset = useSetRecoilState(appsResetAtom);
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteComment = async () => {
    await updateDoc({ col: COLLECTIONS.comments, id: selectedComment.id, data: { active: false } });
    const newTimestamp = getNewLastCommentDate(selectedComment.id, comments);
    await updateDoc({
      col: COLLECTIONS.applications,
      id: selectedComment.applicationId,
      data: { lastComment: newTimestamp },
    });
    setResetComments((pV) => !pV);
    setAppsReset((pV) => !pV);
    handleClose();
  };

  return (
    <>
      {selectedComment && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Are you sure you want to delete this comment?</DialogTitle>
          <DialogContent>
            <DialogContentText>This can not be undone.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Nevermind</Button>
            <Button variant="contained" onClick={handleDeleteComment} autoFocus>
              Yes, I want to delete this comment.
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
