import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { currentStaffAtom } from "./recoil/atoms";
import { useAuth } from "./contexts/AuthContext";

export default function PrivateStaffRoute({ permission, component: Component, ...rest }) {
  const currentStaff = useRecoilValue(currentStaffAtom);
  const { loggedIn, loading } = useAuth();

  const validateRoute = () => {
    if (!loggedIn) return false;
    if (!permission) return true;
    if (currentStaff.sitePermissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {!loading && (
        <>
          {(currentStaff || !loggedIn) && (
            <Route
              {...rest}
              render={(props) => {
                return validateRoute() ? <Component {...props} /> : <Redirect to="/application" />;
              }}
            ></Route>
          )}
        </>
      )}
    </>
  );
}
