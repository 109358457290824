import {
  Button,
  TextField,
  Dialog,
  Select,
  MenuItem,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  appsResetAtom,
  selectedAppAtom,
  editFieldDataAtom,
  editAppFieldOpenAtom,
  appStatusesAtom,
} from "../../recoil/atoms";
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil";
import useUpdateDoc from "../../hooks/useUpdateDoc";

export default function EditField() {
  const setAppsReset = useSetRecoilState(appsResetAtom);
  const setSelectedApp = useSetRecoilState(selectedAppAtom);
  const [editFieldData, setEditFieldData] = useRecoilState(editFieldDataAtom);
  const [editAppFieldOpen, setEditAppFieldOpen] = useRecoilState(editAppFieldOpenAtom);
  const appStatuses = useRecoilValue(appStatusesAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();

  const handleEditSave = async () => {
    const updateData = { [editFieldData.field]: editFieldData.value };
    if (editFieldData.field === "status") {
      const { id } = appStatuses.find((status) => status.value === editFieldData.value);
      if (id) {
        updateData["statusId"] = id;
      }
    }
    await updateDoc({
      col: "applications",
      id: editFieldData.document,
      data: updateData,
    });
    setSelectedApp((pV) => ({ ...pV, [editFieldData.field]: editFieldData.value }));
    setEditAppFieldOpen(false);
    setAppsReset((pV) => !pV);
  };

  const renderField = () => {
    switch (editFieldData.type) {
      case "text":
        return (
          <TextField
            autoFocus
            id="edit"
            type="text"
            fullWidth
            defaultValue={editFieldData.value}
            variant="standard"
            onChange={(e) => setEditFieldData({ ...editFieldData, value: e.target.value })}
          />
        );
      case "select":
        return (
          <Select
            id="demo-simple-select"
            value={editFieldData.value}
            sx={{ minWidth: 200 }}
            onChange={(e) => setEditFieldData({ ...editFieldData, value: e.target.value })}
          >
            {editFieldData.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name ? option.name : option.value}
              </MenuItem>
            ))}
          </Select>
        );
      default:
    }
  };

  return (
    <>
      {editFieldData && (
        <Dialog open={editAppFieldOpen} onClose={() => setEditAppFieldOpen(false)}>
          <DialogTitle>{editFieldData.label}</DialogTitle>
          <DialogContent>{renderField()}</DialogContent>
          <DialogActions>
            <Button onClick={() => handleEditSave()}>Save</Button>
            <Button onClick={() => setEditAppFieldOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
