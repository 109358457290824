import { Button, Typography, Link } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";

export default function StaffLogin() {
  const { signInWithGoogle, setLoginType } = useAuth();

  return (
    <>
      <Typography variant="h2">Staff Sign In</Typography>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={signInWithGoogle}
        sx={{ marginTop: "2em" }}
      >
        Sign In With Google
      </Button>
      <Link
        sx={{ cursor: "pointer", mt: 5 }}
        onClick={() => setLoginType("caregiver")}
        variant="body2"
      >
        {"Switch To Caregiver Login"}
      </Link>
    </>
  );
}
