import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import EditLeadForm from "./EditLeadForm";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { blankLeadForm } from "../../shared/libraries/objects";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { leadsResetAtom, selectedLeadAtom } from "../../recoil/atoms";
import DeleteLeadConfirmation from "./DeleteLeadConfirmation";

export default function EditLeadDialog({ open, setOpen }) {
  const selectedLead = useRecoilValue(selectedLeadAtom);
  const [form, setForm] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const setLeadsReset = useSetRecoilState(leadsResetAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!selectedLead) return;
    if (!selectedLead.owner) {
      setForm({ ...selectedLead, owner: "No Owner" });
    } else {
      setForm({ ...selectedLead });
    }
  }, [open, selectedLead]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    delete form.id;
    delete form.createdAt;
    await updateDoc({ col: "leads", data: { ...form }, id: selectedLead.id });
    setForm(blankLeadForm);
    setOpen(false);
    setLeadsReset((pV) => !pV);
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
      <DialogTitle variant="h5">Edit Lead</DialogTitle>
      <DialogContent>
        {form && <EditLeadForm handleSubmit={handleSubmit} form={form} setForm={setForm} />}
      </DialogContent>
      <DeleteLeadConfirmation
        open={deleteOpen}
        setOpen={setDeleteOpen}
        deleteId={selectedLead.id}
      />
    </Dialog>
  );
}
