import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { selectedLeadAtom } from "../../recoil/atoms";
import { Box, Button, Paper, Typography } from "@mui/material";
import EditLeadDialog from "./EditLeadDialog";
import LeadDetails from "./LeadDetails";

export default function LeadDetailsPanel() {
  const selectedLead = useRecoilValue(selectedLeadAtom);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <Paper sx={{ mt: 1, pt: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", pl: 2, pr: 2 }}>
          <Typography variant="h5" >Lead Details</Typography>
          <Button variant="contained" color="secondary" onClick={handleOpen}>
            Edit
          </Button>
        </Box>
        {selectedLead && <LeadDetails selectedLead={selectedLead} />}
      </Paper>
      <EditLeadDialog open={open} setOpen={setOpen} selectedLead={selectedLead} />
    </>
  );
}
