import React,{useState} from 'react';
import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Box,
} from '@mui/material';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {  filteredAppsByYearAtom, applicationYearStatusAtom} from '../../recoil/atoms';
import { filterByYear } from '../../recoil/util';




const YearFilter = ({filter, filteredApps, setFilteredApps}) => {
    const [alignment, setAlignment] = useState()
    const setApplicationYearStatus = useSetRecoilState(applicationYearStatusAtom)
    const filteredAppsByYear = useRecoilValue(filteredAppsByYearAtom)


   
   const handleClick = (e)=> {
       setApplicationYearStatus(e.target.value);
       console.log(filteredAppsByYear)
   }


  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    
    
    };
  
    return (
        <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
        }}
        >
        <Box>
            <Typography
            variant='h4'
            sx={{ mt: 2, mb: 2 }}
            >
            Filter By Year
            </Typography>
        </Box>
        <Box>
            <ToggleButtonGroup
            color='primary'
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label='Platform'
            sx={{ mt: 1, mb: 1 }}
            >
            <ToggleButton
                value='all'
                onClick={handleClick}
            >
                All
            </ToggleButton>
            <ToggleButton
                value='SY 22 - 23'
                onClick={handleClick}
            >
                Current
            </ToggleButton>
            <ToggleButton
                value='SY 23 - 24'
                onClick={handleClick}
            >
                Next
            </ToggleButton>
            <ToggleButton
                value='SY 21 - 22'
                onClick={handleClick}
            >
                Previous
            </ToggleButton>
            </ToggleButtonGroup>
        </Box>
        </Box>
    );
};
export default YearFilter;