import React from "react";
import { CssBaseline, Container } from "@mui/material";
import NavBar from "../../shared/Navigation/Navbar";
import LeadManagerContainer from "../components/LeadManagerContainer";

export default function LeadManager() {
  return (
    <>
      <CssBaseline />
      <NavBar></NavBar>
      <Container maxWidth="xl" sx={{ minHeight: "100vh", mt: 8 }}>
        <LeadManagerContainer />
      </Container>
    </>
  );
}
