import { useEffect, useState } from "react";
import { db } from "../firebase";
import { doc, getDocs, collection, updateDoc } from "firebase/firestore";
import { COLLECTIONS, SCHOOL_YEARS } from "../libraries/objectsAndArrays";

const CreateUniformAppYearFields = () => {
  const [applications, setApplications] = useState(null);

  useEffect(() => {
    const getApplications = async () => {
      const appRef = collection(db, COLLECTIONS.applications);
      const snapshot = await getDocs(appRef);
      const tempArray = [];
      snapshot.forEach((app) => {
        const email = app.data()?.email.toLowerCase();
        tempArray.push({ ...app.data(), id: app.id, email: email });
      });
      setApplications(tempArray);
    };
    getApplications();
  }, []);

  useEffect(()=>{
    if(!applications) return;
    const appYearCount = {
      next: 0,
      current: 0,
      sy2122: 0,
      sy2223: 0,
      sy2324: 0
    }
    const sy2021Start = new Date("08/01/2020").getTime();
    const sy2122Start = new Date("08/01/2021").getTime();
    const sy2223Start = new Date("08/01/2022").getTime();
    applications.forEach((app)=>{
      const appRef = doc(db, COLLECTIONS.applications, app.id)
      if(app.applicationYear === "current"){
        appYearCount.current++;
        if(app.createdAt.toMillis() >= sy2223Start){
          //console.log("For 22 - 23 School Year")
          updateDoc(appRef, {applicationYear: SCHOOL_YEARS.sy2223})
        }else if(app.createdAt.toMillis() >= sy2122Start){
          //console.log("For 21 - 22 School Year")
          updateDoc(appRef, {applicationYear: SCHOOL_YEARS.sy2122})
        }else if(app.createdAt.toMillis() >= sy2021Start){
          //console.log("For 20 - 21 School Year")
          updateDoc(appRef, {applicationYear: SCHOOL_YEARS.sy2021})
        }
      }else if(app.applicationYear === "next"){
        appYearCount.next++;
        if(app.createdAt.toMillis() >= sy2223Start){
          console.log("For 23 - 24 School Year")
          updateDoc(appRef, {applicationYear: SCHOOL_YEARS.sy2324})
        }else if(app.createdAt.toMillis() >= sy2122Start){
          console.log("For 22 - 23 School Year")
          updateDoc(appRef, {applicationYear: SCHOOL_YEARS.sy2223})
        }else if(app.createdAt.toMillis() >= sy2021Start){
          console.log("For 21 - 22 School Year")
          updateDoc(appRef, {applicationYear: SCHOOL_YEARS.sy2122})
        }
      }else if(app.applicationYear === SCHOOL_YEARS.sy2324){
        appYearCount.sy2324++;
      }
      else if(app.applicationYear === SCHOOL_YEARS.sy2223){
        appYearCount.sy2223++;
      }else if(app.applicationYear === SCHOOL_YEARS.sy2122){
        appYearCount.sy2122++;
      }else{
        //console.log(app.applicationYear)
      }
    })
    console.log(appYearCount)
  },[applications])

  return null;
};

export default CreateUniformAppYearFields;
