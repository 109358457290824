import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedLeadAtom, staffObjAtom, leadsResetAtom } from "../../recoil/atoms";
import { Typography, Grid, Box, Select, MenuItem } from "@mui/material";
import {
  removeNonNumbers,
  formatPhone,
  formatHasChild,
  formatName,
} from "../../shared/libraries/util";
import { LEAD_STATUSES, generateLeadStatusArray } from "../../shared/libraries/objects";
import useUpdateDoc from "../../hooks/useUpdateDoc";

export default function LeadDetails() {
  const staffObj = useRecoilValue(staffObjAtom);
  const selectedLead = useRecoilValue(selectedLeadAtom);
  const strippedPhone = removeNonNumbers(selectedLead.phone);
  const [selectedStatus, setSelectedStatus] = useState();
  const leadsArray = generateLeadStatusArray();
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setLeadsReset = useSetRecoilState(leadsResetAtom);

  useEffect(() => {
    setSelectedStatus(selectedLead?.status || "newLead");
  }, [selectedLead]);

  const handleStatusChange = async (event) => {
    setSelectedStatus(event.target.value);
    await updateDoc({ col: "leads", id: selectedLead.id, data: { status: event.target.value } });
    setLeadsReset((pV) => !pV);
  };

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="leadCardTitle">{`${selectedLead.firstName} ${selectedLead.lastName}`}</Typography>
            <Typography variant="leadCardDate">{selectedLead.submittedOn}</Typography>
            <Typography variant="leadCardInfo">{formatHasChild(selectedLead.hasChild)}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="leadCardInfo">
              <b>Phone: </b>
              <a href={`tel:+${strippedPhone}`}> {formatPhone(strippedPhone)}</a>
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="leadCardInfo">
              <b>Email: </b>
              {selectedLead.email}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="leadCardInfo">
              <b>Lead Source:</b> {selectedLead.source}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {selectedLead.sourceNotes?.length > 1 && (
              <Typography variant="leadCardInfo">
                <b>Source Notes:</b> {selectedLead.sourceNotes}
              </Typography>
            )}
          </Grid>
          <Grid item xs={5}>
            <Typography variant="leadCardInfo">
              <b>Preferred Method:</b> {selectedLead.contactMethod}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="leadCardInfo">
              <b>Lead Owner:</b>{" "}
              {formatName({ staffObj, staffId: selectedLead?.owner }) || "No Owner"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {selectedStatus && (
        <Select
          fullWidth
          disableUnderline
          sx={{ textAlign: "center", backgroundColor: LEAD_STATUSES[selectedStatus].color }}
          variant="standard"
          value={selectedStatus}
          onChange={handleStatusChange}
        >
          {leadsArray.map((lead) => (
            <MenuItem key={lead.key} value={lead.key}>
              {lead.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
}
