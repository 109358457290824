import React, { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedLeadAtom, filteredLeadNotesAtom, leadNotesResetAtom } from "../../recoil/atoms";
import { Box, Button, Paper, Typography, TextField } from "@mui/material";
import useAddDoc from "../../hooks/useAddDoc";
import { useAuth } from "../../contexts/AuthContext";
import LeadNote from "./LeadNote";
import DeleteNoteConfirmation from "./DeleteNoteConfirmation";
import { COLLECTIONS } from "../../libraries/objectsAndArrays";

export default function LeadNotes() {
  const selectedLead = useRecoilValue(selectedLeadAtom);
  const setLeadNotesReset = useSetRecoilState(leadNotesResetAtom);
  const filteredLeadNotes = useRecoilValue(filteredLeadNotesAtom);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteNoteId, setDeleteNoteId] = useState();
  const [newNote, setNewNote] = useState("");
  const { sendRequest: addDoc } = useAddDoc();
  const { currentUser } = useAuth();

  const handleChange = (event) => {
    setNewNote(event.target.value);
  };

  const handleSubmit = async () => {
    await addDoc({
      col: COLLECTIONS.leadNotes,
      data: { leadId: selectedLead.id, text: newNote, authorId: currentUser.email },
    });
    setNewNote("");
    setLeadNotesReset((pV) => !pV);
  };

  return (
    <>
      <Paper sx={{ mt: 1, padding: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Notes</Typography>
          <Button variant="contained" color="secondary" onClick={handleSubmit}>
            Submit New Note
          </Button>
        </Box>
        <TextField sx={{ mt: 2 }} multiline fullWidth value={newNote} onChange={handleChange} />
        <Box sx={{ mt: 2, backgroundColor: "#fafafa", padding: 1, borderRadius: 2 }}>
          {filteredLeadNotes &&
            filteredLeadNotes.map((note) => (
              <LeadNote
                note={note}
                key={note.id}
                setDeleteOpen={setDeleteOpen}
                setDeleteNoteId={setDeleteNoteId}
              />
            ))}
        </Box>
      </Paper>
      <DeleteNoteConfirmation open={deleteOpen} setOpen={setDeleteOpen} deleteNoteId={deleteNoteId} />
    </>
  );
}
