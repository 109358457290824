import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentStaffAtom } from '../../recoil/atoms';
import { SITE_PERMISSIONS_OBJ } from '../libraries/objects';

export default function Web({ collapse, currentUser }) {
	const currentStaff = useRecoilValue(currentStaffAtom);

	return (
		<>
			<Typography
				variant='h6'
				component='div'
				sx={{ flexGrow: 1 }}
			>
				TGP Application System
			</Typography>
			{currentUser && currentUser.access === 'caregiver' && (
				<Link
					className='webLink'
					to='/family-dashboard'
				>
					My Dashboard
				</Link>
			)}
			{currentUser &&
				currentStaff &&
				currentStaff.sitePermissions?.includes(
					SITE_PERMISSIONS_OBJ.see_all_applications
				) && (
					<Link
						className='webLink'
						to='/applicationmanager'
					>
						App Manager
					</Link>
				)}

			{currentUser &&
				currentStaff &&
				currentStaff.sitePermissions?.includes(
					SITE_PERMISSIONS_OBJ.see_all_applications
				) && (
					<Link
						className='webLink'
						to='/application'
					>
						Developer Mode
					</Link>
				)}
			{currentUser && currentUser.access === 'staff' && (
				<Link
					className='webLink'
					to='/leadform'
				>
					Lead Form
				</Link>
			)}
			{currentUser &&
				currentStaff &&
				currentStaff.sitePermissions?.includes(
					SITE_PERMISSIONS_OBJ.upload_leads
				) && (
					<Link
						className='webLink'
						to='/leadupload'
					>
						Upload Leads
					</Link>
				)}
			{currentUser && currentStaff && (
				<Link
					className='webLink'
					to='/leadmanager'
				>
					Lead Manager
				</Link>
			)}
			{currentUser &&
				currentStaff &&
				currentStaff.sitePermissions?.includes(
					SITE_PERMISSIONS_OBJ.edit_staff
				) && (
					<Link
						className='webLink'
						to='/staff'
					>
						Staff
					</Link>
				)}
			{currentUser && currentUser.access === 'staff' && (
				<Link
					className='webLink'
					to='/settings'
				>
					Settings
				</Link>
			)}
			{!currentUser && (
				<Link
					data-sentry-id='nav-login-menu-item'
					className='webLink'
					to='/login'
				>
					Login
				</Link>
			)}
		</>
	);
}
