import { Button, Typography, Grid, Link, TextField, Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function CaregiverLogin() {
  const { setLoginType, signUserIn, loginCredentials, setLoginCredentials, resetPassword } =
    useAuth();
  const history = useHistory();

  const handleChange = (event) => {
    setLoginCredentials((prevValue) => ({ ...prevValue, [event.target.name]: event.target.value }));
  };

  return (
    <>
      <Typography variant="h2">Caregiver Sign In</Typography>
      <Box component="form" onSubmit={(e) => signUserIn(e)} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          type="email"
          value={loginCredentials.email}
          autoFocus
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          value={loginCredentials.password}
          id="password"
          autoComplete="current-password"
          onChange={handleChange}
        />
        <Button fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
          Sign In
        </Button>
        <Grid container>
          <Grid item xs={12}>
            <Link sx={{ cursor: "pointer" }} onClick={resetPassword} variant="body2">
              Forgot Password?
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => history.push("/application")}
              variant="body2"
            >
              {"Don't have an account? Submit an Application to Get Started"}
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link sx={{ cursor: "pointer" }} onClick={() => setLoginType("staff")} variant="body2">
              {"Switch To Staff Login"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
