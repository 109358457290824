import PrimaryCaregiverCard from "./PrimaryCaregiverCard";
import ApplicationCardsContainer from "./ApplicationCardsContainer";

export default function FamilyDashboardContainer() {
  
  return (
    <>
      <PrimaryCaregiverCard />
      <ApplicationCardsContainer />
    </>
  );
}
