import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { selectedAppAtom } from '../../recoil/atoms';
import { useRecoilValue } from 'recoil';
import useGetDocs from '../../hooks/useGetDocs';

export default function AdditionalInfo() {
	const [referralSourceObj, setReferralSourceObj] = useState();
	const { sendRequest: getDocs } = useGetDocs();
	const selectedApp = useRecoilValue(selectedAppAtom);

	useEffect(() => {
		const getReferralSources = async () => {
			const [arr, obj] = await getDocs({ col: 'referralSources' });
			setReferralSourceObj(obj);
		};
		getReferralSources();
	}, []);

	return (
		<Grid
			container
			spacing={1}
		>
			<Grid
				item
				xs={12}
			>
				<Typography
					variant='h5'
					sx={{ mt: 1 }}
				>
					Additional Info:
				</Typography>
			</Grid>
			{referralSourceObj && (
				<Grid
					item
					md={6}
				>
					<b>Referral Source:</b>{' '}
					{selectedApp.referralSourceId
						? referralSourceObj[selectedApp.referralSourceId].name
						: selectedApp.referralSource}
				</Grid>
			)}
			<Grid
				item
				md={6}
			>
				<b>Referral:</b> {selectedApp.referral}
			</Grid>
			<Grid
				item
				md={12}
			>
				<b>Anything Else:</b> {selectedApp.anythingElse}
			</Grid>
		</Grid>
	);
}
