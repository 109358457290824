import { useState, useCallback } from "react";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase";

const useAddDoc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(async ({ col, data }) => {
    setIsLoading(true);
    setError(null);
    try {
      const finalData = { ...data, createdAt: Timestamp.now(), active: true };
      const docRef = collection(db, col);
      const results = await addDoc(docRef, finalData);

      if (!results.id) {
        throw new Error("Request failed!");
      } else {
        return results.id;
      }
    } catch (err) {
      setError(err.message || "Something went wrong!");
    }
    setIsLoading(false);
  }, []);
  return { isLoading, error, sendRequest };
};

export default useAddDoc;
