import React from "react";
import {
  Avatar,
  Box,
  Paper,
  Button,
  Typography,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";

export default function LeadUploadForm({
  loading,
  successMessage,
  changeHandler,
  handleSubmission,
  selectedFile,
  toggle,
  handleToggle,
}) {
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "1em",
        }}
      >
        <Avatar sx={{ width: 120, height: 120, mb: 3 }} src="../TGPicon.png" />
      </Box>
      <Paper>
        <Box sx={{ textAlign: "center", pt: 3 }}>
          <Typography variant="h1">Upload Leads</Typography>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              m: 2,
              mb: 2,
            }}
          >
            <CircularProgress size={60} sx={{ mb: 2 }} />
          </Box>
        ) : (
          <Box sx={{ mt: 3, textAlign: "center" }}>
            {successMessage && <Typography variant="h4">{successMessage}</Typography>}
            <input
              id="uploadFile"
              type="file"
              name="file"
              style={{ display: "none" }}
              onChange={changeHandler}
            />
            <label htmlFor="uploadFile">
              {" "}
              <Button variant="contained" sx={{ margin: 3 }} component="span">
                Choose File
              </Button>
            </label>
            <Button
              variant="contained"
              color="secondary"
              sx={{ margin: 3 }}
              onClick={() => {
                handleSubmission();
              }}
            >
              Submit
            </Button>
            {selectedFile && <Typography>{selectedFile.name}</Typography>}
          </Box>
        )}
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "1em",
          }}
        >
          <FormControlLabel
            checked={toggle}
            onChange={handleToggle}
            control={<Switch />}
            label={toggle ? "Website" : "Facebook"}
          />
        </Box>
      </Paper>
    </>
  );
}
