import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { routes } from "./Routes";
import useBootstrapEffect from "./hooks/useBootstrapEffect";
import useBootstrapAPIEffect from "./hooks/useBootstrapAPIEffect";

const AppBootstrap = () => {
  useBootstrapEffect();
  useBootstrapAPIEffect();
  return (
    <Router>
      <main className="root">{routes}</main>
    </Router>
  );
};

export default AppBootstrap;
