import {
  leadsAtom,
  leadSortDirectionAtom,
  leadSortFieldAtom,
  leadNotesAtom,
  selectedLeadAtom,
  leadFilteredStatusesAtom,
  leadFilteredStaffAtom,
  currentStaffAtom,
  leadFilteredSearchAtom,
  appsAtom,
  filteredAppStatusIdsAtom,
  
  applicationYearStatusAtom,
  filteredAppsAtom,
 


} from "./atoms";
import { sortTimestampByKey } from "../shared/libraries/util";
import { SITE_PERMISSIONS_OBJ } from "../shared/libraries/objects";
import {
  filterByName,
  filterByStatus,
  filterByOwner,
  filterByPermissions,
  sortLeads,
  
  
} from "./util";



export const sortedLeadsGetter = ({ get }) => {
  const leads = get(leadsAtom);
  const currentStaff = get(currentStaffAtom);
  const leadSortDirection = get(leadSortDirectionAtom);
  const leadSortField = get(leadSortFieldAtom);
  const leadFilteredStatuses = get(leadFilteredStatusesAtom);
  const leadFilteredStaff = get(leadFilteredStaffAtom);
  const leadFilteredSearch = get(leadFilteredSearchAtom);
  let leadsArray = [...leads];
  leadsArray = filterByName({ leadsArray, leadFilteredSearch });
  leadsArray = filterByStatus({ leadsArray, leadFilteredStatuses });
  leadsArray = filterByOwner({ leadsArray, leadFilteredStaff });
  leadsArray = filterByPermissions({ currentStaff, SITE_PERMISSIONS_OBJ, leadsArray });
  leadsArray = sortLeads({ leadSortField, leads, leadSortDirection, leadsArray });
  return leadsArray;
};

export const filteredLeadNotesGetter = ({ get }) => {
  const leadNotes = get(leadNotesAtom);
  const selectedLead = get(selectedLeadAtom);
  const filteredLeads = leadNotes.filter((note) => note.leadId === selectedLead.id);
  return sortTimestampByKey({ array: filteredLeads, key: "createdAt" }) || [];
};

export const filteredAppsGetter = ({ get }) => {
  const apps = get(appsAtom);
  const filteredAppStatusIds = get(filteredAppStatusIdsAtom);
  if (!apps || !filteredAppStatusIds) return;
  const filteredApps = apps.filter((app) => filteredAppStatusIds.includes(app.statusId));
  return filteredApps.sort((a,b) => b.createdAt - a.createdAt)
  
};

export const filterApplicationsByYearGetter = ({get}) => {
    const applicationYearStatus = get(applicationYearStatusAtom);
    const filteredApps = get(filteredAppsAtom); 
    if(!filteredApps) return 
    if (applicationYearStatus === "all"){
      return filteredApps
    }
     return filteredApps.filter((item) => item.applicationYear === applicationYearStatus)


  }
  
