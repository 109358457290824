import { Typography, Container, FormControlLabel, Checkbox } from "@mui/material";
import { appStatusesAtom, filteredAppStatusIdsAtom } from "../../recoil/atoms";
import { useRecoilValue, useRecoilState } from "recoil";

export default function CustomFilters() {
  const [filteredAppStatusIds, setFilteredAppStatusIds] = useRecoilState(filteredAppStatusIdsAtom);
  const appStatuses = useRecoilValue(appStatusesAtom);

  const handleChange = (event) => {
    const index = filteredAppStatusIds.findIndex((status) => status === event.target.name);
    const tempArray = [...filteredAppStatusIds];
    if (index === -1) {
      tempArray.push(event.target.name);
    } else {
      tempArray.splice(index, 1);
    }
    setFilteredAppStatusIds(tempArray);
  };

  return (
    <Container sx={{ mt: 1, mb: 1 }}>
      {appStatuses &&
        filteredAppStatusIds &&
        appStatuses.map((status) => (
          <FormControlLabel
            key={status.id}
            control={
              <Checkbox
                checked={filteredAppStatusIds.includes(status.id)}
                name={status.id}
                onChange={handleChange}
              />
            }
            label={<Typography variant="labelText">{status.value}</Typography>}
          />
        ))}
    </Container>
  );
}
