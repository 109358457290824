import React from 'react';
import { Paper, TextField, Button, Grid } from '@mui/material';

const AddReferralSource = ({ setSourceForm, handleSubmit }) => {
	const handleChange = (e) => {
		e.preventDefault();
		setSourceForm((prevValue) => {
			return { ...prevValue, [e.target.name]: e.target.value };
		});
	};

	return (
		<Paper sx={{ padding: 3 }}>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					sm={6}
					xs={12}
				>
					<TextField
						margin='normal'
						required
						fullWidth
						label='Source Name'
						name='name'
						onChange={handleChange}
						autoFocus
					/>
				</Grid>
				<Grid
					item
					sm={6}
					xs={12}
				>
					<TextField
						margin='normal'
						required
						fullWidth
						label='Author'
						name='author'
						onChange={handleChange}
						autoFocus
					/>
				</Grid>
			</Grid>

			<Grid
				container
				spacing={2}
				sx={{ mt: 2 }}
			>
				<Grid
					item
					sm={12}
					xs={12}
				>
					<TextField
						margin='normal'
						required
						fullWidth
						label='Description'
						name='description'
						onChange={handleChange}
						autoFocus
					/>
				</Grid>
			</Grid>
			<Button
				type='submit'
				fullWidth
				variant='contained'
				sx={{ mt: 3, mb: 2 }}
				onClick={handleSubmit}
			>
				Add Source
			</Button>
		</Paper>
	);
};
export default AddReferralSource;
