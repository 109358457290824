import { Card, CardContent, Typography, Grid } from "@mui/material";
import { SCHOOL_YEARS } from "../../libraries/objectsAndArrays";

export default function ApplicationCard({ application }) {
  return (
    <Card sx={{ marginTop: 1 }}>
      <CardContent>
        <Typography variant="h2" color="text.secondary" sx={{ marginBottom: 4 }}>
          Application for {application.childFirstName}:
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12}>
            <b>First Name:</b> {application.childFirstName}
          </Grid>
          <Grid item md={6} sm={12}>
            <b>Last Name:</b> {application.childLastName}
          </Grid>
          <Grid item md={12} sm={12}>
            <b>Adress:</b> {application.address}
          </Grid>
          {application.aptNum && (
            <Grid item md={12} sm={12}>
              <b>Apt Number:</b> {application.aptNum}
            </Grid>
          )}
          <Grid item md={6} sm={12}>
            <b>City:</b> {application.city}
          </Grid>
          <Grid item md={6} sm={12}>
            <b>State:</b> {application.state}
          </Grid>
          <Grid item md={6} sm={12}>
            <b>Zip:</b> {application.zip}
          </Grid>
          <Grid item md={6} sm={12}>
            <b>Birthday:</b> {application.birthday}
          </Grid>
          <Grid item md={12} sm={12}>
            <b>Year Applying For:</b>{" "}
            {application.applicationYear === SCHOOL_YEARS.sy2223
              ? "I'm Looking for an Immediate Seat"
              : "I'm Applying for Next School Year"}
          </Grid>
          <Grid item md={6} sm={12}>
            <b>Grade Applying For:</b> {application.applicationGrade}
          </Grid>
          <Grid item md={6} sm={12}>
            <b>Status:</b> {application.status}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
