import React from 'react';
import { Typography, Paper, Box } from '@mui/material';
import ReferralSourceContainer from './ReferralSourceContainer';

const SettingsContainer = () => {
	return (
		<>
			<Typography
				sx={{ mt: 3, mb: 2 }}
				variant='h3'
			>
				Settings
			</Typography>
			<Paper sx={{ padding: 2 }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
					<Typography
						variant='h4'
						sx={{ mt: 2, mb: 2 }}
					>
						Add New Referral
					</Typography>
					<Typography
						variant='h4'
						sx={{ mt: 2, mb: 2 }}
					>
						Referral Sources
					</Typography>
				</Box>
				<ReferralSourceContainer />
			</Paper>
		</>
	);
};

export default SettingsContainer;
