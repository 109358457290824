import { useState, useCallback } from "react";
import { doc, updateDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase";

const useUpdateDoc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(async ({ col, data, id }) => {
    setIsLoading(true);
    setError(null);
    try {
      const finalData = { ...data, lastUpdatedAt: Timestamp.now() };
      const docRef = doc(db, col, id);
      await updateDoc(docRef, finalData);
    } catch (err) {
      setError(err.message || "Something went wrong!");
      console.log(err);
    }
    setIsLoading(false);
  }, []);
  return { isLoading, error, sendRequest };
};

export default useUpdateDoc;
