import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { staffAtom, leadFilteredStaffAtom, currentStaffAtom } from "../../recoil/atoms";
import { FormControlLabel, Box, Checkbox, Typography } from "@mui/material";
import {} from "../../recoil/atoms";
import { SITE_PERMISSIONS_OBJ } from "../../shared/libraries/objects";

export default function FilterOwnerSelect() {
  const [leadFilteredStaff, setLeadFilteredStaff] = useRecoilState(leadFilteredStaffAtom);
  const staff = useRecoilValue(staffAtom);
  const currentStaff = useRecoilValue(currentStaffAtom);

  useEffect(() => {
    if (!staff) return;
    const tempStaffArray = ["none"];
    staff.forEach((staffMember) => {
      tempStaffArray.push(staffMember.id);
    });
    setLeadFilteredStaff(tempStaffArray);
  }, [staff, setLeadFilteredStaff]);

  const handleStaffChange = (event) => {
    const staffArray = [...leadFilteredStaff];
    if (staffArray.indexOf(event.target.name) > -1) {
      staffArray.splice(staffArray.indexOf(event.target.name), 1);
    } else {
      staffArray.push(event.target.name);
    }
    setLeadFilteredStaff(staffArray);
  };

  return (
    <>
      {currentStaff && currentStaff?.sitePermissions?.includes(SITE_PERMISSIONS_OBJ.see_all_leads) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            mt: 2,
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography sx={{ mr: 2 }} variant="h6">
            Lead Owners:{" "}
          </Typography>
          <FormControlLabel
            key="none"
            control={
              <Checkbox
                checked={leadFilteredStaff.includes("none")}
                onChange={handleStaffChange}
                name="none"
              />
            }
            label={<Typography variant={"body2"}>No Owner</Typography>}
          />
          {leadFilteredStaff &&
            staff.map((staffMember) => (
              <FormControlLabel
                key={staffMember.id}
                control={
                  <Checkbox
                    checked={leadFilteredStaff.includes(staffMember.id)}
                    onChange={handleStaffChange}
                    name={staffMember.id}
                  />
                }
                label={<Typography variant={"body2"}>{staffMember.firstName}</Typography>}
              />
            ))}
        </Box>
      )}
    </>
  );
}
