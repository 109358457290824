import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import {
  sortedLeadsAtom,
  leadSortFieldAtom,
  leadSortDirectionAtom,
  selectedLeadAtom,
  bulkAssignCounterAtom,
} from "../../recoil/atoms";
import { Grid, Box } from "@mui/material";
import LeadCard from "./LeadCard";
import LeadDetailsPanel from "./LeadDetailsPanel";
import FilterContainer from "./FilterContainer";
import BulkAssignPanel from "./BulkAssignPanel";
import LeadNotes from "./LeadNotes";
import useWindowDimensions from "../../shared/useWindowDimensions";

export default function LeadManagerContainer() {
  const sortedLeads = useRecoilValue(sortedLeadsAtom);
  const setLeadSortField = useSetRecoilState(leadSortFieldAtom);
  const setLeadSortDirection = useSetRecoilState(leadSortDirectionAtom);
  const [selectedLead, setSelectedLead] = useRecoilState(selectedLeadAtom);
  const [bulkAssignEnabled, setBulkAssignEnabled] = useState(false);
  const [bulkCounter, setBulkCounter] = useRecoilState(bulkAssignCounterAtom);
  const [bulkSelectedLeads, setBulkSelectedLeads] = useState([]);
  const [leadViewHeight, setLeadViewHeight] = useState("85vh");
  const { collapse } = useWindowDimensions();

  useEffect(() => {
    if (collapse && selectedLead) {
      setLeadViewHeight("150px");
    } else if (collapse) {
      setLeadViewHeight("50vh");
    } else {
      setLeadViewHeight("85vh");
    }
  }, [collapse, selectedLead]);

  useEffect(() => {
    if (selectedLead) {
      const [updatedLead] = sortedLeads.filter((lead) => lead.id === selectedLead.id);
      setSelectedLead(updatedLead);
    }
  }, [selectedLead, sortedLeads, setSelectedLead]);

  const handleSort = (event) => {
    setLeadSortField(event.target.name);
    setLeadSortDirection((pV) => ({
      ...pV,
      [event.target.name]: !pV[event.target.name],
    }));
  };

  const handleBulkAssign = () => {
    setBulkAssignEnabled((pV) => !pV);
    setSelectedLead(null);
    setBulkSelectedLeads([]);
  };

  return (
    <Box sx={{ pt: 2 }}>
      <FilterContainer
        handleSort={handleSort}
        handleBulkAssign={handleBulkAssign}
        bulkAssignEnabled={bulkAssignEnabled}
      />
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item sm={5}>
          <Box sx={{ maxHeight: leadViewHeight, overflow: "scroll" }}>
            {sortedLeads &&
              sortedLeads.map((lead) => (
                <LeadCard
                  key={lead.id}
                  lead={lead}
                  setBulkSelectedLeads={setBulkSelectedLeads}
                  bulkAssignEnabled={bulkAssignEnabled}
                  bulkSelectedLeads={bulkSelectedLeads}
                  setBulkCounter={setBulkCounter}
                />
              ))}
          </Box>
        </Grid>
        <Grid item sm={7}>
          {selectedLead && !bulkAssignEnabled && (
            <Box sx={{ maxHeight: "85vh", overflow: "scroll" }}>
              <LeadDetailsPanel />
              <LeadNotes />
            </Box>
          )}
          {bulkAssignEnabled && (
            <BulkAssignPanel
              bulkSelectedLeads={bulkSelectedLeads}
              setBulkAssignEnabled={setBulkAssignEnabled}
              setBulkSelectedLeads={setBulkSelectedLeads}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
