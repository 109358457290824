import { Grid, Typography } from "@mui/material";
import CustomEditIcon from "./CustomEditIcon";
import { FAB_ICON } from "../../libraries/FAB_ICON";
import { SCHOOL_YEARS } from "../../libraries/objectsAndArrays";
import { appStatusesAtom, selectedAppAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";

export default function AccountInfo({ showFabIcon, setShowFabIcon }) {
  const appStatuses = useRecoilValue(appStatusesAtom);
  const selectedApp = useRecoilValue(selectedAppAtom);

  const tourRequested = selectedApp.tourRequested ? selectedApp.tourRequested : "Not Asked";

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mt: 1 }}>
          Account Info:
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        sm={12}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.APP_YEAR)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <Typography>
          <b>App Year:</b> {selectedApp.applicationYear}
          {showFabIcon === FAB_ICON.APP_YEAR && (
            <CustomEditIcon
              size={25}
              label="Application Year - (Remember to also change the grade level they're applying for if necessary.)"
              value={selectedApp.applicationYear}
              collection="applications"
              document={selectedApp.id}
              field="applicationYear"
              type="select"
              options={[
                { value: SCHOOL_YEARS.sy2122, name: "The 2021 - 2022 School Year" },
                { value: SCHOOL_YEARS.sy2223, name: "The 2022 - 2023 School Year" },
                { value: SCHOOL_YEARS.sy2324, name: "The 2023 - 2024 School Year" },
                { value: SCHOOL_YEARS.sy2425, name: "The 2024 - 2025 School Year" },
              ]}
            />
          )}
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.ACCOUNT_OWNER)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>Owner:</b> {"\u00A0" + selectedApp.accountOwner || "Not Selected"}
        {showFabIcon === FAB_ICON.ACCOUNT_OWNER && (
          <CustomEditIcon
            size={25}
            label="Account Owner"
            value={selectedApp.accountOwner || "Not Selected"}
            collection="applications"
            document={selectedApp.id}
            field="accountOwner"
            type="select"
            options={[
              { value: "Erin", name: "Erin" },
              { value: "Janda", name: "Janda" },
              { value: "Liz", name: "Liz" },
              { value: "Chelsea", name: "Chelsea" },
              { value: "Robin", name: "Robin" },
              { value: "Vic", name: "Vic" },
            ]}
          />
        )}
      </Grid>
      <Grid
        item
        md={6}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.STATUS)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>Status:</b> {"\u00A0" + selectedApp.status}
        {showFabIcon === FAB_ICON.STATUS && (
          <CustomEditIcon
            size={25}
            label="Status"
            value={selectedApp.status}
            collection="applications"
            document={selectedApp.id}
            field="status"
            type="select"
            options={appStatuses}
          />
        )}
      </Grid>
      <Grid
        item
        md={6}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.TOURREQUESTED)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>Tour Requested:</b> {"\u00A0" + tourRequested}
        {showFabIcon === FAB_ICON.TOURREQUESTED && (
          <CustomEditIcon
            size={25}
            label="Tour Requested?"
            value={tourRequested}
            collection="applications"
            document={selectedApp.id}
            field="tourRequested"
            type="select"
            options={[
              { value: "Yes", name: "Yes" },
              { value: "No", name: "No" },
              { value: "Not Asked", name: "Not Asked" },
            ]}
          />
        )}
      </Grid>
      {selectedApp && selectedApp.status === "Caregiver Turned Seat Down" && (
        <Grid
          item
          md={12}
          onMouseEnter={() => setShowFabIcon(FAB_ICON.WHYDECLINE)}
          onMouseLeave={() => setShowFabIcon()}
        >
          <b>Reason For Decline:</b> {"\u00A0" + selectedApp.whyDecline}
          {showFabIcon === FAB_ICON.WHYDECLINE && (
            <CustomEditIcon
              size={25}
              label="Why They Declined?"
              value={selectedApp.whyDecline}
              collection="applications"
              document={selectedApp.id}
              field="whyDecline"
              type="select"
              options={[
                { value: "No Before School Care", name: "No Before School Care" },
                { value: "No After School Care", name: "No After School Care" },
                { value: "No Transportation", name: "No Transportation" },
                { value: "Live Too Far Away", name: "Live Too Far Away" },
                {
                  value: "Worried about Bullying or Behavior",
                  name: "Worried about Bullying or Behavior",
                },
                { value: "Worried about Academics", name: "Worried about Academics" },
                {
                  value: "Siblings in Grades We Don't Offer",
                  name: "Siblings in Grades We Don't Offer",
                },
                { value: "Worreid About COVID Protocols", name: "Worreid About COVID Protocols" },
                {
                  value: "Worried about Equity / School Values",
                  name: "Worried about Equity / School Values",
                },
                { value: "Wanted Smaller Class Sizes", name: "Wanted Smaller Class Sizes" },
                { value: "Not Reported", name: "Not Reported" },
              ]}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
}
