import React, { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { staffObjAtom, leadNotesResetAtom, currentStaffAtom } from "../../recoil/atoms";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { DateTime } from "luxon";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { formatAvatar, formatName } from "../../shared/libraries/util";
import useUpdateDoc from "../../hooks/useUpdateDoc";

export default function LeadNote({ note, setDeleteOpen, setDeleteNoteId }) {
  const staffObj = useRecoilValue(staffObjAtom);
  const setLeadNotesReset = useSetRecoilState(leadNotesResetAtom);
  const [noteText, setNoteText] = useState(note?.text || "");
  const [editMode, setEditMode] = useState(false);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const currentStaff = useRecoilValue(currentStaffAtom);

  const dateFormat = (timestamp) => {
    const dt = DateTime.fromMillis(timestamp.toMillis());
    return dt.toFormat("D");
  };

  const handleEditToggle = () => {
    setEditMode((pV) => !pV);
  };

  const handleEdit = (event) => {
    setNoteText(event.target.value);
  };

  const handleSave = async () => {
    await updateDoc({ col: "leadNotes", id: note.id, data: { text: noteText } });
    setEditMode(false);
    setLeadNotesReset((pV) => !pV);
  };

  const openDeleteDialog = () => {
    setDeleteOpen(true);
    setDeleteNoteId(note.id);
  };

  return (
    <Card sx={{ mt: 1, mb: 1 }}>
      <CardHeader
        title={formatName({ staffObj, staffId: note.authorId })}
        avatar={<Avatar>{formatAvatar({ staffObj, staffId: note.authorId })}</Avatar>}
        subheader={dateFormat(note.createdAt)}
        action={
          <>
            {!editMode && currentStaff?.id === note.authorId && (
              <IconButton onClick={handleEditToggle}>
                <EditIcon />
              </IconButton>
            )}
            {editMode && (
              <>
                <IconButton onClick={handleSave}>
                  <SaveIcon color="primary" />
                </IconButton>
                <IconButton onClick={handleEditToggle}>
                  <CloseIcon />
                </IconButton>
                <IconButton onClick={openDeleteDialog}>
                  <DeleteIcon color="error" />
                </IconButton>
              </>
            )}
          </>
        }
      />
      <CardContent sx={{ pt: 0 }}>
        {!editMode && <Typography variant="body2">{note.text}</Typography>}
        {editMode && (
          <TextField fullWidth multiline value={noteText} onChange={handleEdit}></TextField>
        )}
      </CardContent>
    </Card>
  );
}
