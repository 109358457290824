import React from "react";
import { Grid } from "@mui/material";
import FilterSort from "./FilterSort";
import FilterStatusSelect from "./FilterStatusSelect";
import FilterOwnerSelect from "./FilterOwnerSelect";
import FilterSearch from "./FilterSearch";
import ExportCSV from "./ExportCSV";

export default function FilterContainer({ handleSort, bulkAssignEnabled, handleBulkAssign }) {
  return (
    <>
      <FilterSort
        handleSort={handleSort}
        bulkAssignEnabled={bulkAssignEnabled}
        handleBulkAssign={handleBulkAssign}
      />
      <FilterStatusSelect
        handleSort={handleSort}
        bulkAssignEnabled={bulkAssignEnabled}
        handleBulkAssign={handleBulkAssign}
      />
      <Grid container spacing={2} alignItems="center">
        <Grid item sm={7} xs={12}>
          <FilterOwnerSelect
            handleSort={handleSort}
            bulkAssignEnabled={bulkAssignEnabled}
            handleBulkAssign={handleBulkAssign}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <ExportCSV />
        </Grid>
        <Grid item sm={3} xs={12}>
          <FilterSearch />
        </Grid>
      </Grid>
    </>
  );
}
