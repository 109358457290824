export const parseApplicationsResponse = (response) =>
	response.map((app) => ({
		id: app?.id ?? '',
		accountOwner: app?.accountOwner ?? '',
		address: app?.address ?? '',
		applicationGrade: app?.applicationGrade ?? '',
		applicationYear: app?.applicationYear ?? '',
		assignedDistrict: app?.assignedDistrict ?? '',
		birthday: app?.birthday ?? '',
		caregiverFirstName: app?.caregiverFirstName ?? '',
		caregiverLastName: app?.caregiverLastName ?? '',
		caregiverId: app?.caregiverId ?? '',
		childFirstName: app?.childFirstName ?? '',
		childLastName: app?.childLastName ?? '',
		city: app?.city ?? '',
		createdAt: app?.createdAt ?? '',
		email: app?.email ?? '',
		familyID: app?.familyID ?? '',
		lastComment: app?.lastComment ?? '',
		lastStatusChangedAt: app?.lastStatusChangedAt ?? '',
		phone: app?.phone ?? '',
		primaryCaregiverUID: app?.primaryCaregiverUID ?? '',
		referralSource: app?.referralSource ?? '',
		referralSourceId: app?.referralSourceId ?? '',
		state: app?.state ?? '',
		status: app?.status ?? '',
		statusId: app?.statusId ?? '',
		whyDecline: app?.whyDecline ?? "",
		tourRequested: app?.tourRequested ?? '',
		zip: app?.zip ?? '',
		active: app?.active ?? true,
	}));

export const parseApplicationStatusesResponse = (response) =>
	response.map((appStatus) => ({
		id: appStatus?.id ?? '',
		default: appStatus?.default ?? true,
		value: appStatus?.value ?? '',
		stage: appStatus?.stage ?? '',
		active: appStatus?.active ?? true,
	}));
