import { Fab } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useSetRecoilState } from "recoil";
import { editFieldDataAtom, editAppFieldOpenAtom } from "../../recoil/atoms";

export default function CustomEditIcon({
  size,
  label,
  value,
  collection,
  document,
  field,
  type,
  options,
}) {
  const setEditFieldData = useSetRecoilState(editFieldDataAtom);
  const setEditAppFieldOpen = useSetRecoilState(editAppFieldOpenAtom);
  const handleEditFieldOpen = (label, value, collection, document, field, type, options) => {
    setEditFieldData({
      label: label,
      value: value,
      collection: collection,
      document: document,
      field: field,
      type: type,
      options: options,
    });
    setEditAppFieldOpen(true);
  };
  return (
    <Fab
      color="secondary"
      sx={{
        width: size,
        lineHeight: size,
        height: size,
        minHeight: size,
        ml: 2,
      }}
      size="small"
      aria-label="edit"
      onClick={() => handleEditFieldOpen(label, value, collection, document, field, type, options)}
    >
      <EditIcon sx={{ fontSize: size * 0.72 }} />
    </Fab>
  );
}
