import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { getAppCardStatusStyle } from "../../libraries/functions";

export default function AppCard({ app, onRowClick }) {
  const renderDate = (date) => {
    const dateAsMillis = date.toMillis();
    const newDate = new Date(dateAsMillis);
    return newDate.toLocaleDateString();
  };

  return (
    <>
      <Card sx={{ margin: 2 }}>
        <CardContent>
          <CardActionArea
            onClick={() => {
              onRowClick(app);
            }}
          >
            <Typography variant="cardEntry" sx={{ mb: 1 }}>
              <b>Account Owner: </b>
              {app.accountOwner}
            </Typography>
            <Typography variant="cardEntry" sx={{ mb: 1 }}>
              <b>App Year: </b>
              <span>{app.applicationYear}</span>
            </Typography>
            <Typography variant="cardEntry" sx={{ mb: 1 }}>
              <b>Submitted: </b>
              {renderDate(app.createdAt)}
            </Typography>
            <Typography variant="cardEntry" sx={{ mb: 1 }}>
              <b>Child's First Name: </b>
              {app.childFirstName}
            </Typography>
            <Typography variant="cardEntry" sx={{ mb: 1 }}>
              <b>Child's First Name: </b>
              {app.childLastName}
            </Typography>
            <Typography variant="cardEntry" sx={{ mb: 1 }}>
              <b>App Status: </b>
              <span style={getAppCardStatusStyle(app.status)}>{app.status}</span>
            </Typography>
          </CardActionArea>
          <CardActionArea
            onClick={() => {
              onRowClick(app);
            }}
          >
            <Typography variant="cardEntry">
              <b>Phone: </b>
              <span style={{ color: "#26C6F5" }}>{app.phone}</span>
            </Typography>
          </CardActionArea>
        </CardContent>
      </Card>
    </>
  );
}
