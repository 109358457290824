import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import NavBar from "../../shared/Navigation/Navbar";
import LeadFormContainer from "../components/LeadFormContainer";

const LeadForm = () => {
  return (
    <>
      <CssBaseline />
      <NavBar></NavBar>
      <Container maxWidth="md" sx={{ minHeight: "100vh", mt: 8, pt: 2 }}>
        <LeadFormContainer />
      </Container>
    </>
  );
};

export default LeadForm;
