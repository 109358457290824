import { useEffect, useState } from "react";
import { db } from "../firebase";
import { getDocs, collection, addDoc } from "firebase/firestore";
import { COLLECTIONS } from "../libraries/objectsAndArrays";

const ConvertCaregiversToEmail = () => {
  const [originalCaregivers, setOriginalCaregivers] = useState(null);
  useEffect(() => {
    const getOriginalCaregivers = async () => {
      const caregiverRef = collection(db, "caregivers");
      const snapshot = await getDocs(caregiverRef);
      const tempArray = [];
      snapshot.forEach((cg) => {
        tempArray.push({ ...cg.data(), email: cg.data().email.toLowerCase() });
      });
      setOriginalCaregivers(tempArray);
    };
    getOriginalCaregivers();
  }, []);

  useEffect(() => {
    if (!originalCaregivers) return;
    const checkNewCaregivers = async () => {
      const caregiverRef = collection(db, COLLECTIONS.caregivers);
      const snapshot = await getDocs(caregiverRef);
      const newCaregivers = [];
      let count = 0;
      snapshot.forEach((newCaregiver) => {
        newCaregivers.push({ ...newCaregiver.data() });
        count++;
        console.log(count);
      });
      originalCaregivers.forEach((originalCaregiver) => {
        const check = newCaregivers.find(
          (newCaregiver) =>
            newCaregiver.email.toLowerCase() === originalCaregiver.email.toLowerCase()
        );
        if (!check) {
          addDoc(collection(db, COLLECTIONS.caregivers), { ...originalCaregiver });
        }
      });
    };
    checkNewCaregivers();
  });

  return null;
};

export default ConvertCaregiversToEmail;
