import { useState } from "react";
import { Dialog, Divider, DialogContent, Typography, Grid } from "@mui/material";
import AccountInfo from "./AccountInfo";
import ChildInfo from "./ChildInfo";
import CaregiverInfo from "./CaregiverInfo";
import SiblingInfo from "./SiblingInfo";
import AdditionalInfo from "./AdditionalInfo";
import DeleteApp from "./DeleteApp";
import CommentsContainer from "./CommentsContainer";

export default function AppDialog({ appDialogOpen, setAppDialogOpen }) {
  const [showFabIcon, setShowFabIcon] = useState(null);
  return (
    <Dialog
      open={appDialogOpen}
      onClose={() => setAppDialogOpen(false)}
      maxWidth="xl"
      fullWidth={true}
      sx={{ margin: window.innerWidth * 0.005 }}
    >
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item lg={7} md={9} sm={12}>
            <Typography variant="h3">Information</Typography>
            <Divider />
            <>
              <AccountInfo showFabIcon={showFabIcon} setShowFabIcon={setShowFabIcon} />
              <ChildInfo showFabIcon={showFabIcon} setShowFabIcon={setShowFabIcon} />
              <CaregiverInfo showFabIcon={showFabIcon} setShowFabIcon={setShowFabIcon} />
              <SiblingInfo showFabIcon={showFabIcon} setShowFabIcon={setShowFabIcon} />
              <AdditionalInfo showFabIcon={showFabIcon} setShowFabIcon={setShowFabIcon} />
            </>
            <DeleteApp setAppDialogOpen={setAppDialogOpen} />
          </Grid>
          <Grid item lg={5} md={3} sm={12}>
            <Typography variant="h3">Notes</Typography>
            <Divider />
            <CommentsContainer />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
