import React, { useState, useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import Papa from "papaparse";
import { DateTime } from "luxon";
import useAddDoc from "../../hooks/useAddDoc";
import LeadUploadForm from "./LeadUploadForm";
import { leadsAtom, leadsResetAtom } from "../../recoil/atoms";
import { LEAD_SOURCES, LEAD_CONTACT_METHODS } from "../../shared/libraries/objects";
import { COLLECTIONS } from "../../libraries/objectsAndArrays";

const LeadUploadContainer = () => {
  const leads = useRecoilValue(leadsAtom);
  const setLeadsReset = useSetRecoilState(leadsResetAtom);
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const { sendRequest: addDoc } = useAddDoc();
  const [toggle, setToggle] = useState(true);
  const [last, setLast] = useState(false);
  const [leadsCount, setLeadsCount] = useState(0);

  useEffect(() => {
    if (!last || loading) {
      return;
    }
    setSuccessMessage(`${leadsCount} Leads Uploaded`);
    setLoading(false);
    setSelectedFile(null);
    setLeadsReset((pV) => !pV);
  }, [last, loading, setLeadsReset, leadsCount]);

  const handleToggle = () => {
    setToggle((pV) => !pV);
  };

  const searchLeads = (data) => {
    const results = leads.filter((lead) => {
      return lead.phone === data.phone || lead.email === data.email;
    });
    return results;
  };

  const fromWebsite = async ({ value }) => {
    if (!value["Phone"] || !value["Your First Name"]) return;
    const hasChild =
      value["do you have a child in kindergarten  7th grade"] === "Yes" ? true : false;
    const lead = {
      submittedOn: value["Submitted On"].slice(0, 10) || "",
      firstName: value["Your First Name"] || "",
      lastName: value["Your Last Name"] || "",
      phone: value["Phone"] || "",
      email: value["Email"] || "",
      source: LEAD_SOURCES.website || "",
      sourceNotes: "",
      contactMethod: value["What is your preferred way to be contacted"] || "",
      hasChild: hasChild,
      status: "newLead",
      owner: "none",
    };
    const search = searchLeads(lead);
    if (search.length === 0) {
      setLoading(true);
      await addDoc({ col: COLLECTIONS.leads, data: lead });
      setLeadsCount((pV) => {
        return pV + 1;
      });
      setLoading(false);
    }
  };

  const splitFullName = (fullName) => {
    const spaceIndex = fullName.indexOf(" ");
    let firstName = "";
    let lastName = "";
    if (spaceIndex > -1) {
      firstName = fullName.slice(0, fullName.indexOf(" "));
      lastName = fullName.slice(fullName.indexOf(" ") + 1);
    } else {
      firstName = fullName;
    }
    return { firstName, lastName };
  };

  const convertFBContactMethod = (value) => {
    switch (value["how_do_you_want_us_to_reach_out?"]) {
      case "call":
        return LEAD_CONTACT_METHODS.call;
      case "text":
        return LEAD_CONTACT_METHODS.text;
      case "email":
        return LEAD_CONTACT_METHODS.email;
      default:
        return LEAD_CONTACT_METHODS.text;
    }
  };

  const fromFacebook = async ({ value }) => {
    if (!value["phone_number"]) return;
    const submittedDateTime = DateTime.fromISO(value["created_time"]);
    const splitName = splitFullName(value["full_name"]);
    const hasChild =
      value["do_you_have_a_child_in_kindergarten_-_7th_grade?"] === "yes" ? true : false;

    const lead = {
      submittedOn: submittedDateTime.toFormat("D") || "",
      firstName: splitName.firstName,
      lastName: splitName.lastName,
      phone: value["phone_number"] || "",
      email: value["email"] || "",
      source: LEAD_SOURCES.facebook,
      sourceNotes: `${value["ad_name"]} Ad` || "",
      contactMethod: convertFBContactMethod(value),
      hasChild: hasChild,
      status: "newLead",
      owner: "none",
    };
    const search = searchLeads(lead);
    if (search.length === 0) {
      setLoading(true);
      await addDoc({ col: "leads", data: lead });
      setLeadsCount((pV) => {
        return pV + 1;
      });
      setLoading(false);
    }
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmission = () => {
    if (selectedFile && selectedFile.type === "text/csv") {
      Papa.parse(selectedFile, {
        complete: function (results) {
          const length = results.data.length;
          results.data.forEach((value, index) => {
            if (length === index + 1) {
              setLast(true);
            }
            if (toggle) {
              fromWebsite({ value });
            } else {
              fromFacebook({ value });
            }
          });
        },
        header: true,
      });
    }
  };

  return (
    <LeadUploadForm
      changeHandler={changeHandler}
      handleSubmission={handleSubmission}
      handleToggle={handleToggle}
      successMessage={successMessage}
      loading={loading}
      selectedFile={selectedFile}
      toggle={toggle}
    />
  );
};

export default LeadUploadContainer;
