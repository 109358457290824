import { useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { selectedAppAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import useAddDoc from "../../hooks/useAddDoc";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { COLLECTIONS } from "../../libraries/objectsAndArrays";
import { Timestamp } from "firebase/firestore";

export default function NewComment({ setResetComments }) {
  const { currentUser } = useAuth();
  const [newComment, setNewComment] = useState("");
  const selectedApp = useRecoilValue(selectedAppAtom);
  const { sendRequest: addDoc } = useAddDoc();
  const { sendRequest: updateDoc } = useUpdateDoc();

  //TODO: Need to finish making the comment show up upon submission (it hits DB, just not comment array)

  const handleSubmitNewComment = async () => {
    await addDoc({
      col: COLLECTIONS.comments,
      data: {
        applicationId: selectedApp.id,
        author: currentUser.displayName,
        comment: newComment,
        timestamp: Timestamp.now(),
      },
    });
    await updateDoc({
      col: COLLECTIONS.applications,
      id: selectedApp.id,
      data: { lastComment: Timestamp.now() },
    });
    setResetComments((pV) => !pV);
    setNewComment("");
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          type="text"
          style={{ marginTop: "1em" }}
          name="commInput"
          label="Internal Staff Notes"
          value={newComment}
          multiline
          rows={4}
          onChange={(e) => setNewComment(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <Button variant="contained" sx={{ marginBottom: 4 }} onClick={handleSubmitNewComment}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}
