import { useState, useEffect } from "react";
import { Container } from "@mui/material";
import NewComment from "./NewComment";
import Comment from "./Comment";
import EditCommentDialog from "./EditCommentDialog";
import DeleteCommentDialog from "./DeleteCommentDialog";
import useGetDocs from "../../hooks/useGetDocs";
import { selectedAppAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { COLLECTIONS } from "../../libraries/objectsAndArrays";

export default function CommentsContainer() {
  const [comments, setComments] = useState();
  const [resetComments, setResetComments] = useState(false);
  const { sendRequest: getDocs } = useGetDocs();
  const selectedApp = useRecoilValue(selectedAppAtom);
  const [selectedComment, setSelectedComment] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    if (!selectedApp) return;
    const getComments = async () => {
      const [arr] = await getDocs({
        col: COLLECTIONS.comments,
        config: { where: ["applicationId", "==", selectedApp.id], orderBy: ["timestamp", "desc"] },
      });
      setComments(arr);
    };
    getComments();
  }, [selectedApp, getDocs, resetComments]);

  return (
    <>
      {comments && (
        <>
          <NewComment setResetComments={setResetComments} />
          <Container sx={{ maxHeight: 600, overflow: "auto" }}>
            {comments.map((comment) => (
              <Comment
                key={comment.id}
                comment={comment}
                setSelectedComment={setSelectedComment}
                setEditOpen={setEditOpen}
                setDeleteOpen={setDeleteOpen}
              ></Comment>
            ))}
          </Container>
        </>
      )}
      <EditCommentDialog
        selectedComment={selectedComment}
        open={editOpen}
        setOpen={setEditOpen}
        setResetComments={setResetComments}
      />
      <DeleteCommentDialog
        selectedComment={selectedComment}
        open={deleteOpen}
        setOpen={setDeleteOpen}
        setResetComments={setResetComments}
        comments={comments}
      />
    </>
  );
}
