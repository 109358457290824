import { Grid, Typography } from "@mui/material";
import CustomEditIcon from "./CustomEditIcon";
import { FAB_ICON } from "../../libraries/FAB_ICON";
import { selectedAppAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";

export default function CaregiverInfo({ showFabIcon, setShowFabIcon }) {
  const selectedApp = useRecoilValue(selectedAppAtom);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mt: 1 }}>
          Caregiver's Info:
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.CAREGIVER_FIRST_NAME)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>First Name:</b> {selectedApp.caregiverFirstName}
        {showFabIcon === FAB_ICON.CAREGIVER_FIRST_NAME && (
          <CustomEditIcon
            size={25}
            label="Caregiver's First Name"
            value={selectedApp.caregiverFirstName}
            collection="applications"
            document={selectedApp.id}
            field="caregiverFirstName"
            type="text"
          />
        )}
      </Grid>
      <Grid
        item
        md={6}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.CAREGIVER_LAST_NAME)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>Last Name:</b> {selectedApp.caregiverLastName}
        {showFabIcon === FAB_ICON.CAREGIVER_LAST_NAME && (
          <CustomEditIcon
            size={25}
            label="Caregiver's Last Name"
            value={selectedApp.caregiverLastName}
            collection="applications"
            document={selectedApp.id}
            field="caregiverLastName"
            type="text"
          />
        )}
      </Grid>
      <Grid
        item
        md={6}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.PHONE)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>Phone:</b> {selectedApp.phone}
        {showFabIcon === FAB_ICON.PHONE && (
          <CustomEditIcon
            size={25}
            label="Caregiver's Phone"
            value={selectedApp.phone}
            collection="applications"
            document={selectedApp.id}
            field="phone"
            type="text"
          />
        )}
      </Grid>
      <Grid
        item
        md={6}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.EMAIL)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>Email:</b> {selectedApp.email}
        {showFabIcon === FAB_ICON.EMAIL && (
          <CustomEditIcon
            size={25}
            label="Caregiver's Email"
            value={selectedApp.email}
            collection="applications"
            document={selectedApp.id}
            field="email"
            type="text"
          />
        )}
      </Grid>
      <Grid
        item
        md={6}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.ADDRESS)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>Address</b> {selectedApp.address}
        {showFabIcon === FAB_ICON.ADDRESS && (
          <CustomEditIcon
            size={25}
            label="Address"
            value={selectedApp.address}
            collection="applications"
            document={selectedApp.id}
            field="address"
            type="text"
          />
        )}
      </Grid>
      <Grid
        item
        md={6}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.APT_NUM)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>Apt Num:</b> {selectedApp.aptNum}
        {showFabIcon === FAB_ICON.APT_NUM && (
          <CustomEditIcon
            size={25}
            label="Apt Number"
            value={selectedApp.aptNum}
            collection="applications"
            document={selectedApp.id}
            field="aptNum"
            type="text"
          />
        )}
      </Grid>
      <Grid
        item
        md={4}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.CITY)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>City:</b> {selectedApp.city}
        {showFabIcon === FAB_ICON.CITY && (
          <CustomEditIcon
            size={25}
            label="City"
            value={selectedApp.city}
            collection="applications"
            document={selectedApp.id}
            field="city"
            type="text"
          />
        )}
      </Grid>
      <Grid
        item
        md={4}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.STATE)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>State:</b> {selectedApp.state}
        {showFabIcon === FAB_ICON.STATE && (
          <CustomEditIcon
            size={25}
            label="State"
            value={selectedApp.state}
            collection="applications"
            document={selectedApp.id}
            field="state"
            type="text"
          />
        )}
      </Grid>
      <Grid
        item
        md={4}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.ZIP)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>Zip:</b> {selectedApp.zip}
        {showFabIcon === FAB_ICON.ZIP && (
          <CustomEditIcon
            size={25}
            label="Zip Code"
            value={selectedApp.zip}
            collection="applications"
            document={selectedApp.id}
            field="zip"
            type="text"
          />
        )}
      </Grid>
    </Grid>
  );
}
