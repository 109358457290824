import Container from "@mui/material/Container";
import NavBar from "../shared/Navigation/Navbar";
import FamilyDashboardContainer from "../components/FamilyDashboard/FamilyDashboardContainer";

const FamilyDashboardPage = () => {
  return (
    <>
      <NavBar></NavBar>
      <Container sx={{ minHeight: "100vh", mt: 8, pt: 2 }}>
        <FamilyDashboardContainer />
      </Container>
    </>
  );
};

export default FamilyDashboardPage;
