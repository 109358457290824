import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";

export default function PrivateCaregiverRoute({ permission, component: Component, ...rest }) {
  const { loggedIn, loading } = useAuth();

  const validateRoute = () => {
    if (!loggedIn) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {!loading && (
        <Route
          {...rest}
          render={(props) => {
            return validateRoute() ? <Component {...props} /> : <Redirect to="/application" />;
          }}
        ></Route>
      )}
    </>
  );
}
