import { Grid, Typography } from "@mui/material";
import CustomEditIcon from "./CustomEditIcon";
import { FAB_ICON } from "../../libraries/FAB_ICON";
import { formatBirthday } from "../../libraries/functions";
import { selectedAppAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";

export default function ChildInfo({ showFabIcon, setShowFabIcon }) {
  const selectedApp = useRecoilValue(selectedAppAtom);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mt: 1 }}>
          Child's Info:
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.FIRST_NAME)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>First Name:</b> {selectedApp.childFirstName}
        {showFabIcon === FAB_ICON.FIRST_NAME && (
          <CustomEditIcon
            size={25}
            id="childFirstName"
            label="Child's First Name"
            value={selectedApp.childFirstName}
            collection="applications"
            document={selectedApp.id}
            field="childFirstName"
            type="text"
          />
        )}
      </Grid>
      <Grid
        item
        md={6}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.LAST_NAME)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>Last Name:</b> {selectedApp.childLastName}
        {showFabIcon === FAB_ICON.LAST_NAME && (
          <CustomEditIcon
            size={25}
            label="Child's Last Name"
            value={selectedApp.childLastName}
            collection="applications"
            document={selectedApp.id}
            field="childLastName"
            type="text"
          />
        )}
      </Grid>
      <Grid
        item
        md={6}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.BIRTHDAY)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>Birthday:</b> {formatBirthday(selectedApp.birthday)}
        {showFabIcon === FAB_ICON.BIRTHDAY && (
          <CustomEditIcon
            size={25}
            label="Child's Birthday"
            value={selectedApp.birthday}
            collection="applications"
            document={selectedApp.id}
            field="birthday"
            type="text"
          />
        )}
      </Grid>
      <Grid
        item
        md={6}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.ASSIGNED_DISTRICT)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>Assigned District:</b> {selectedApp.assignedDistrict}
        {showFabIcon === FAB_ICON.ASSIGNED_DISTRICT && (
          <CustomEditIcon
            size={25}
            label="Assigned District"
            value={selectedApp.assignedDistrict}
            collection="applications"
            document={selectedApp.id}
            field="assignedDistrict"
            type="text"
          />
        )}
      </Grid>
      <Grid
        item
        md={6}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.GRADE)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>Grade:</b> {selectedApp.applicationGrade}
        {showFabIcon === FAB_ICON.GRADE && (
          <CustomEditIcon
            size={25}
            label="Grade Level"
            value={selectedApp.applicationGrade}
            collection="applications"
            type="select"
            options={[
              { value: "Kindergarten", name: "Kindergarten" },
              { value: "1st Grade", name: "1st Grade" },
              { value: "2nd Grade", name: "2nd Grade" },
              { value: "3rd Grade", name: "3rd Grade" },
              { value: "4th Grade", name: "4th Grade" },
              { value: "5th Grade", name: "5th Grade" },
            ]}
            document={selectedApp.id}
            field="applicationGrade"
          />
        )}
      </Grid>
    </Grid>
  );
}
