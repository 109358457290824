import React, { useEffect, useState } from 'react';
import {
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditReferralForm from '../Settings/EditReferralForm';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { selectedSourceAtom, sourceResetAtom } from '../../recoil/atoms';
import { blankSourceForm } from '../../shared/libraries/objects';
import useUpdateDoc from '../../hooks/useUpdateDoc';
import { Box } from '@mui/material';

export default function SourceDialog({ open, setOpen }) {
	const selectedSource = useRecoilValue(selectedSourceAtom);
	const [form, setForm] = useState();
	const setSourceReset = useSetRecoilState(sourceResetAtom);
	const { sendRequest: updateDoc } = useUpdateDoc();

	useEffect(() => {
		if (!selectedSource) {
			return;
		} else {
			setForm({ ...selectedSource });
		}
	}, [open, selectedSource]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		await updateDoc({
			col: 'referralSources',
			id: selectedSource.id,
			data: { ...form },
		});
		setSourceReset((pV) => !pV);
		setForm(blankSourceForm);
		setOpen(false);
	};

	const handleDelete = async () => {
		await updateDoc({
			col: 'referralSources',
			id: selectedSource.id,
			data: { active: false },
		});
		handleClose();
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
		>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<DialogTitle
					variant='h5'
					sx={{ padding: 4 }}
				>
					Edit Referral Source
				</DialogTitle>
				<DialogActions>
					<IconButton onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</DialogActions>
			</Box>

			<DialogContent>
				{form && (
					<EditReferralForm
						handleSubmit={handleSubmit}
						handleDelete={handleDelete}
						form={form}
						setForm={setForm}
						selectedSource={selectedSource}
					/>
				)}
			</DialogContent>
		</Dialog>
	);
}
