import { useEffect, useState } from "react";
import { db } from "../firebase";
import { doc, getDocs, collection, updateDoc } from "firebase/firestore";
import { COLLECTIONS } from "../libraries/objectsAndArrays";

const UpdateAppsToStatusIds = () => {
  const [applications, setApplications] = useState();
  const [statusesObj, setStatusesObj] = useState();

  useEffect(() => {
    const getApplications = async () => {
      const ref = collection(db, COLLECTIONS.applications);
      const snapshot = await getDocs(ref);
      const tempArray = [];
      snapshot.forEach((row) => {
        tempArray.push({ ...row.data(), id: row.id });
      });
      setApplications(tempArray);
    };
    getApplications();
  }, []);

  useEffect(() => {
    const getStatuses = async () => {
      const ref = collection(db, COLLECTIONS.statuses);
      const snapshot = await getDocs(ref);
      const tempObj = {};
      snapshot.forEach((row) => {
        tempObj[row.data().value] = row.id;
      });
      setStatusesObj(tempObj);
    };
    getStatuses();
  }, []);

  useEffect(() => {
    if (!applications || !statusesObj) return;
    const promises = [];
    const updateStatuses = async () => {
      applications.forEach((app) => {
        const docRef = doc(db, COLLECTIONS.applications, app.id);
        const statusId = statusesObj[app.status];
        if (statusId) {
          promises.push(updateDoc(docRef, { statusId: statusId }));
        }
      });
      console.log("started updating")
      const done = await Promise.all(promises);
      console.log("Done means we are now ", done);
    };
    updateStatuses();
  }, [applications, statusesObj]);

  return null;
};

export default UpdateAppsToStatusIds;
