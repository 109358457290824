import clsx from "clsx";

const renderDate = ({ value }) => {
  if (typeof value === "number") {
    const newDate = new Date(value);
    return newDate.toLocaleDateString();
  } else {
    return "";
  }
};

const getDateForSort = ({ value }) => {
  if (value && value.toMillis()) {
    return value.toMillis();
  } else {
    return "";
  }
};

export const columns = [
  {
    field: "accountOwner",
    headerName: "Lead",
    flex: 0.5,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "createdAt",
    headerName: "Submitted Date",
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    valueGetter: getDateForSort,
    valueFormatter: renderDate,
    type: "number",
    flex: 1,
  },
  {
    field: "applicationYear",
    headerName: "App Year",
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    flex: 1,
  },
  {
    field: "childFirstName",
    headerName: "First Name",
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    flex: 1,
  },
  {
    field: "childLastName",
    headerName: "Last Name",
    flex: 1,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "caregiverFirstName",
    headerName: "Caregiver First Name",
    flex: 1,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "caregiverLastName",
    headerName: "Caregiver Last Name",
    flex: 1,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "applicationGrade",
    headerName: "Grade",
    flex: 1,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "phone",
    headerName: "Phone",
    flex: 1,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
  },
  {
    field: "lastComment",
    headerName: "Last Comment",
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    flex: 1,
    valueGetter: getDateForSort,
    valueFormatter: renderDate,
  },
  {
    field: "tourRequested",
    headerName: "Requested Tour?",
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    flex: 0.5,
  },
  {
    field: "status",
    headerClassName: "tableHeader",
    headerName: "Status",
    flex: 1,
    cellClassName: (params) =>
      clsx("super-app", {
        cellGrey: params.value === "Pending",
        cellYellow: params.value === "Offered a Seat",
        cellGreen: params.value === "Accepted Seat",
        cellPurple: params.value === "Partially Registered",
        cellBlue: params.value === "Fully Registered for SY 22 - 23" || params.value === "Active",
        cellOrange: params.value === "Waitlisted",
        cellRed:
          params.value === "Caregiver Turned Seat Down" ||
          params.value === "Ineligible Due to Address" ||
          params.value === "Ineligible Due to Birthday",
      }),
  },
  {
    field: "whyDecline",
    headerName: "Reason for Decline?",
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    flex: 1,
  },
];
