import React from "react";
import {
  Typography,
  TextField,
  Select,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Switch,
  Button,
  Divider,
} from "@mui/material";
import { LEAD_CONTACT_METHODS, LEAD_SOURCES } from "../../shared/libraries/objects";
import { useRecoilValue } from "recoil";
import { staffAtom } from "../../recoil/atoms";
import DeleteLeadConfirmation from "./DeleteLeadConfirmation";

export default function EditLeadForm({ handleSubmit, form, setForm }) {
  const staff = useRecoilValue(staffAtom);
  const handleChange = (e) => {
    setForm((pV) => ({ ...pV, [e.target.name]: e.target.value }));
  };

  const handleSwitchChange = (e) => {
    setForm((pV) => ({ ...pV, [e.target.name]: e.target.checked }));
  };
  return (
    <form onSubmit={handleSubmit} style={{ margin: 8 }}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            id="firstName"
            name="firstName"
            label="Your First Name"
            variant="outlined"
            required
            fullWidth
            value={form.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            id="lastName"
            name="lastName"
            label="Your Last Name"
            variant="outlined"
            required
            fullWidth
            value={form.lastName}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item sm={6} xs={12}>
          <TextField
            id="phone"
            name="phone"
            label="Your Phone Number"
            variant="outlined"
            required
            fullWidth
            value={form.phone}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            name="email"
            id="email"
            label="Your Email"
            variant="outlined"
            fullWidth
            value={form.email}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="contactMethodLabel">Best Way To Reach You</InputLabel>
            <Select
              labelId="contactMethodLabel"
              id="contactMethod"
              name="contactMethod"
              label="Best Way To Reach You"
              value={form.contactMethod}
              onChange={handleChange}
            >
              <MenuItem value={LEAD_CONTACT_METHODS.text}>{LEAD_CONTACT_METHODS.text}</MenuItem>
              <MenuItem value={LEAD_CONTACT_METHODS.call}>{LEAD_CONTACT_METHODS.call}</MenuItem>
              <MenuItem value={LEAD_CONTACT_METHODS.email}>{LEAD_CONTACT_METHODS.email}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            name="submittedOn"
            id="submittedOn"
            label="Submitted On"
            variant="outlined"
            fullWidth
            value={form.submittedOn}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item sm={12} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="sourceLabel">Lead Source</InputLabel>
            <Select
              labelId="sourceLabel"
              id="source"
              name="source"
              label="Lead Source"
              value={form.source}
              onChange={handleChange}
            >
              <MenuItem value={LEAD_SOURCES.tabling}>{LEAD_SOURCES.tabling}</MenuItem>
              <MenuItem value={LEAD_SOURCES.website}>{LEAD_SOURCES.website}</MenuItem>
              <MenuItem value={LEAD_SOURCES.facebook}>{LEAD_SOURCES.facebook}</MenuItem>
              <MenuItem value={LEAD_SOURCES.event}>{LEAD_SOURCES.event}</MenuItem>
              <MenuItem value={LEAD_SOURCES.coldCall}>{LEAD_SOURCES.coldCall}</MenuItem>
              <MenuItem value={LEAD_SOURCES.other}>{LEAD_SOURCES.other}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item sm={12} xs={12}>
          <TextField
            name="sourceNotes"
            id="sourceNotes"
            label="Notes"
            variant="outlined"
            fullWidth
            value={form.sourceNotes}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item sm={12} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="owner">Owner</InputLabel>
            <Select
              labelId="ownerLabel"
              id="owner"
              name="owner"
              label="Owner"
              value={form.owner}
              onChange={handleChange}
            >
              <MenuItem key={"No Owner"} value={"No Owner"}>
                No Owner
              </MenuItem>
              {staff &&
                staff.map((staffMember) => (
                  <MenuItem key={staffMember.id} value={staffMember.id}>
                    {staffMember.firstName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item sm={12} xs={12}>
          <Typography>Do you have a child going into kindergarten through 4th grade?</Typography>
          <FormControlLabel
            control={
              <Switch name="hasChild" checked={form.hasChild} onChange={handleSwitchChange} />
            }
            label={form.hasChild ? "Yes" : "No"}
          />
        </Grid>
      </Grid>
      <Button sx={{ mt: 2 }} type="submit" variant="contained" fullWidth>
        Submit
      </Button>
      <Divider sx={{mt: 2}} />
      <Button sx={{ mt: 2 }} variant="contained" color="error" fullWidth>
        Delete Lead
      </Button>
    </form>
  );
}
