import React, { useState } from "react";
import { Typography, Alert } from "@mui/material";
import LeadForm from "./LeadForm";
import { COLLECTIONS } from "../../libraries/objectsAndArrays";
import { blankLeadForm } from "../../shared/libraries/objects";
import useAddDoc from "../../hooks/useAddDoc";
import { DateTime } from "luxon";

export default function LeadFormContainer() {
  const [form, setForm] = useState(blankLeadForm);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { sendRequest: addDoc } = useAddDoc();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addDoc({
      col: COLLECTIONS.leads,
      data: {
        ...form,
        status: "newLead",
        submittedOn: DateTime.now().toFormat("D"),
        owner: "none",
      },
    });
    setForm(blankLeadForm);
    setFormSubmitted(true);
  };

  const handleAlertReset = () => {
    setFormSubmitted(false);
  };
  return (
    <>
      <Typography sx={{ mt: 2, mb: 1, textAlign: "center" }} variant="h3">
        Lead Form
      </Typography>
      {formSubmitted && (
        <Alert sx={{ mt: 1, mb: 2 }} severity="success" onClose={handleAlertReset}>
          Form Submitted Successfully!
        </Alert>
      )}
      <LeadForm form={form} setForm={setForm} handleSubmit={handleSubmit} />
    </>
  );
}
