import React from "react";
import { TextField, Box } from "@mui/material";
import { useRecoilState } from "recoil";
import { leadFilteredSearchAtom } from "../../recoil/atoms";

export default function FilterSearch() {
  const [leadFilteredSearch, setLeadFilteredSearch] = useRecoilState(leadFilteredSearchAtom);
  const handleChange = (event) => {
    setLeadFilteredSearch(event.target.value.toLowerCase());
  };
  return (
    <Box sx={{ mt: 2 }}>
      <TextField fullWidth label="Search" value={leadFilteredSearch} onChange={handleChange} />
    </Box>
  );
}
