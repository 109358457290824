export const FAB_ICON = {
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    BIRTHDAY: "birthday",
    ASSIGNED_DISTRICT: "assigned_district",
    APP_YEAR: "applicationYear",
    GRADE: "grade",
    CAREGIVER_FIRST_NAME: "caregiver_first_name",
    CAREGIVER_LAST_NAME: "caregiver_last_name",
    PHONE: "phone",
    EMAIL: "email",
    ADDRESS: "address",
    APT_NUM: "apt_num",
    CITY: "city",
    STATE: "state",
    ZIP: "zip",
    STATUS: "status",
    SIBLING: "sibling",
    ACCOUNT_OWNER: "account_owner",
    TOURREQUESTED: "tour_requested",
  };