import { Grid, Typography } from "@mui/material";
import CustomEditIcon from "./CustomEditIcon";
import { FAB_ICON } from "../../libraries/FAB_ICON";
import { selectedAppAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";

export default function SiblingInfo({ showFabIcon, setShowFabIcon }) {
  const selectedApp = useRecoilValue(selectedAppAtom);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mt: 1 }}>
          Sibling's Info:
        </Typography>
      </Grid>
      {selectedApp.siblings && (
        <Grid item xs={12}>
          <Typography variant="body1" component="span">
            <b>Siblings</b>{" "}
          </Typography>
          {selectedApp.siblings.map((sibling) => (
            <Typography key={sibling.id} variant="body1" component="span">
              {sibling.childFirstName + " " + sibling.childLastName + " "}
            </Typography>
          ))}
        </Grid>
      )}

      <Grid
        item
        md={12}
        onMouseEnter={() => setShowFabIcon(FAB_ICON.SIBLING)}
        onMouseLeave={() => setShowFabIcon()}
      >
        <b>Caregiver Sibling Notes:</b> {selectedApp.sibling}
        {showFabIcon === FAB_ICON.SIBLING && (
          <CustomEditIcon
            size={25}
            id="sibling"
            label="Caregiver Siblings"
            value={selectedApp.sibling}
            collection="applications"
            document={selectedApp.id}
            field="sibling"
            type="text"
          />
        )}
      </Grid>
    </Grid>
  );
}
