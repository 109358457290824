import { useState, useEffect } from "react";
import { Typography, Paper, TextField, Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import AppManagerTable from "./AppManagerTable";
import CustomFilters from "./CustomFilters";
import YearFilter from "./YearFilters";
import CardContainer from "./CardContainer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { filterByValue, getSiblings } from "../../libraries/functions";
import { appsAtom, filteredAppsByYearAtom } from "../../recoil/atoms";
import AppDialogContainer from "../AppDialog/AppDialogContainer";
import { selectedAppAtom } from "../../recoil/atoms";
import { useSetRecoilState } from "recoil";



const AppManagerContainer = () => {
  const filteredAppsByYear = useRecoilValue(filteredAppsByYearAtom);
  const apps = useRecoilValue(appsAtom);
  const [searchedApps, setSearchedApps] = useState([]);
  const [appDialogOpen, setAppDialogOpen] = useState(false);
  const setSelectedApp = useSetRecoilState(selectedAppAtom);

  useEffect(() => {
    setSearchedApps(filteredAppsByYear);
  }, [filteredAppsByYear]);

  const searchApplications = (e) => {
    const tempArray = filterByValue(filteredAppsByYear, e.target.value);
    setSearchedApps(tempArray);
  };
  
  const onRowClick = async (data) => {
    const siblings = getSiblings({ apps: apps, data: data });
    setSelectedApp({ ...data, siblings: siblings });
    setAppDialogOpen(true);
  };

  const { collapse } = useWindowDimensions();
  return (
    <>
      <Typography sx={{ mt: 3, mb: 2 }} variant="h3">
        Application Manager
      </Typography>
      <Paper sx={{ padding: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
            Summary Table
          </Typography>
          <Box>
            <TextField placeholder={"Search"} onChange={searchApplications} />
          </Box>
        </Box>
        <CustomFilters />
        <YearFilter/>
        {!collapse && <AppManagerTable filteredApps={searchedApps} onRowClick={onRowClick} />}
      </Paper>
      {collapse && <CardContainer onRowClick={onRowClick} />}
      <AppDialogContainer appDialogOpen={appDialogOpen} setAppDialogOpen={setAppDialogOpen} />
    </>
  );
};

export default AppManagerContainer;
