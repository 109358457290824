import { Typography } from '@mui/material';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { bulkAssignCounterAtom } from '../../recoil/atoms';

const BulkAssignCounter = () => {
	const bulkCounter = useRecoilValue(bulkAssignCounterAtom);
	return (
		<div>
			<Typography
				sx={{ mr: 2, p: 2 }}
				variant='leadCardDate'
			>
				{' '}
				Total Assigned: {bulkCounter}
			</Typography>
		</div>
	);
};

export default BulkAssignCounter;
