import React from 'react';
import { Typography, TextField } from '@mui/material';

function FormTextField({ handleChange, label, name, required, value }) {
	return (
		<>
			<Typography
				variant='body1'
				style={{ marginTop: '1em' }}
			>
				{label}
			</Typography>
			<TextField
				fullWidth
				required={required}
				type='text'
				style={{ marginTop: '1em' }}
				id={name}
				name={name}
				onChange={handleChange}
				value={value}
			/>
		</>
	);
}

export default FormTextField;
