import { useState, useEffect } from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import SetPasswordDialog from "./SetPasswordDialog";
export default function PrimaryCaregiverCard() {
  const { currentUser } = useAuth();

  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);

  useEffect(() => {
    if (!currentUser) return;
    if (currentUser.tempPassword) {
      setPasswordDialogOpen(true);
    }
  }, [currentUser]);

  return (
    <>
      {currentUser && (
        <>
          <Card sx={{ marginTop: 5 }}>
            <CardContent>
              <Typography variant="h2" color="text.secondary" sx={{ marginBottom: 4 }}>
                Primary Caregiver Information:
              </Typography>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12}>
                  <b>First Name:</b> {currentUser.firstName}
                </Grid>
                <Grid item md={6} sm={12}>
                  <b>Last Name:</b> {currentUser.lastName}
                </Grid>
                <Grid item md={6} sm={12}>
                  <b>Email:</b> {currentUser.email}
                </Grid>
                <Grid item md={6} sm={12}>
                  <b>Phone:</b> {currentUser.phone}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <SetPasswordDialog open={passwordDialogOpen} setOpen={setPasswordDialogOpen} />
        </>
      )}
    </>
  );
}
