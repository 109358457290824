import AppDialog from "./AppDialog";
import EditField from "./EditField";

export default function AppDialogContainer({ appDialogOpen, setAppDialogOpen }) {
  return (
    <>
      <AppDialog appDialogOpen={appDialogOpen} setAppDialogOpen={setAppDialogOpen} />
      <EditField />
    </>
  );
}
