import { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { CSVLink } from "react-csv";
import { useRecoilValue } from "recoil";
import { sortedLeadsAtom } from "../../recoil/atoms";

const leadsHeaders = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Email", key: "email" },
  { label: "Phone", key: "phone" },
  { label: "Preferred Method", key: "contactMethod" },
];

function ExportCSV() {
  const [leadsForExport, setLeadsForExport] = useState([]);
  const sortedLeads = useRecoilValue(sortedLeadsAtom);

  useEffect(() => {
    const tempArray = [];
    sortedLeads.forEach((lead) => {
      tempArray.push({
        firstName: lead?.firstName ?? "",
        lastName: lead?.lastName ?? "",
        email: lead?.email ?? "",
        phone: lead?.phone ?? "",
        contactMethod: lead?.contactMethod ?? "",
      });
    });
    setLeadsForExport(tempArray);
  }, [sortedLeads]);
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <CSVLink
          data={leadsForExport}
          headers={leadsHeaders}
          style={{ textDecoration: "none" }}
          filename={`Leads Export`}
        >
          <Button fullWidth variant="contained" sx={{ padding: 2 }}>
            EXPORT CSV
          </Button>
        </CSVLink>
      </Box>
    </>
  );
}

export default ExportCSV;
