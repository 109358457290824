import { useState, useEffect } from "react";
import { Grid, Box, Button, Divider, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import useGetDocs from "../../hooks/useGetDocs";
import { useAuth } from "../../contexts/AuthContext";
import ApplicationCard from "./ApplicationCard";

export default function ApplicationCardsContainer() {
  const [currentApplications, setCurrentApplications] = useState(null);
  const { sendRequest: getDocs } = useGetDocs();
  const { currentUser } = useAuth();
  const history = useHistory();

  const handleNewApp = () => {
    history.push("/application");
  };

  useEffect(() => {
    if(!currentUser || !currentUser.id) return
    const getApplications = async () => {
      const [applicationsArray] = await getDocs({
        col: "applications",
        config: { where: ["caregiverId", "==", currentUser.id] },
      });
      setCurrentApplications(applicationsArray);
    };
    getApplications();
  }, [currentUser, getDocs]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ textAlign: "center", marginTop: 5 }}>
          <Typography variant="h3">Current Applications</Typography>
          <Button onClick={handleNewApp} sx={{ marginTop: 2, marginBottom: 2 }} variant="contained">
            Submit Additional Application
          </Button>
          <Divider />
        </Box>
      </Grid>
      {currentApplications &&
        currentApplications.map((app, i) => (
          <Grid key={app.id} item md={6} sm={12} xs={12}>
            <ApplicationCard application={app} i={i} />
          </Grid>
        ))}
    </Grid>
  );
}
