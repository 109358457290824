import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Badge from "@mui/material/Badge";
import { useAuth } from "../../contexts/AuthContext";
import useWindowDimensions from "../useWindowDimensions";
import Mobile from "./Mobile";
import Web from "./Web";

export default function ButtonAppBar() {
  const { currentUser } = useAuth();
  const { history } = useHistory();
  const { logout } = useAuth();
  const { collapse } = useWindowDimensions();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      history.push("login");
    } catch {}
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={{ displayPrint: "none" }} position="fixed">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {collapse ? (
            <Mobile
              anchorEl={anchorEl}
              collapse={collapse}
              handleClick={handleClick}
              handleClose={handleClose}
              currentUser={currentUser}
            />
          ) : (
            <Web
              anchorEl={anchorEl}
              collapse={collapse}
              handleClick={handleClick}
              handleClose={handleClose}
              currentUser={currentUser}
            />
          )}

          {currentUser && (
            <IconButton aria-label="logout" color="inherit" onClick={handleLogout}>
              <Badge color="secondary">
                <ExitToAppIcon />
              </Badge>
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
