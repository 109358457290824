import React from "react";
import { Typography, TextField } from "@mui/material";

function AnythingElse({handleChange}) {
  return (
    <>
      <Typography variant="body1" style={{ marginTop: "1em" }}>
        Anything else you want us to know?
      </Typography>
      <TextField
        fullWidth
        type="text"
        style={{ marginTop: "1em" }}
        id="anythingElse"
        name="anythingElse"
        multiline
        rows="4"
        onChange={handleChange}
      />
    </>
  );
}

export default AnythingElse;
