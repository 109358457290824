import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./contexts/AuthContext";
import { RecoilRoot } from "recoil";
import { theme } from "./Theme";
import AppBootstrap from "./AppBootstrap";

const App = () => {
  return (
    <RecoilRoot>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <AppBootstrap />
        </ThemeProvider>
      </AuthProvider>
    </RecoilRoot>
  );
};

export default App;
