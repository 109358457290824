import { useEffect, useState } from "react";
import { db } from "../firebase";
import { doc, getDocs, collection, updateDoc } from "firebase/firestore";
import { COLLECTIONS } from "../libraries/objectsAndArrays";

const AddCaregiverIdToApplications = () => {
  const [applications, setApplications] = useState(null);
  const [caregivers, setCaregivers] = useState(null);
  useEffect(() => {
    const getApplications = async () => {
      const appRef = collection(db, COLLECTIONS.applications);
      const snapshot = await getDocs(appRef);
      const tempArray = [];
      snapshot.forEach((app) => {
        const email = app.data()?.email.toLowerCase();
        tempArray.push({ ...app.data(), id: app.id, email: email });
      });
      setApplications(tempArray);
    };
    getApplications();
  }, []);

  useEffect(() => {
    const getCaregivers = async () => {
      const caregiverRef = collection(db, COLLECTIONS.caregivers);
      const snapshot = await getDocs(caregiverRef);
      const tempArray = [];
      snapshot.forEach((cg) => {
        const email = cg.data()?.email.toLowerCase();
        tempArray.push({ ...cg.data(), id: cg.id, email: email });
      });
      setCaregivers(tempArray);
    };
    getCaregivers();
  }, []);

  useEffect(() => {
    if (!applications || !caregivers) return;
    const updateApps = async () => {
      const promises = [];
      applications.forEach((app) => {
        const caregiver = caregivers.find((cg) => app.familyID === cg.familyID);
        if (caregiver) {
          const appRef = doc(db, COLLECTIONS.applications, app.id);
          promises.push(updateDoc(appRef, { caregiverId: caregiver.id }));
        }
      });
      console.log("Started adding CG Ids to apps");
      await Promise.all(promises);
      console.log("Finished adding CG Ids to apps");
    };
    updateApps();
  }, [applications, caregivers]);

  return null;
};

export default AddCaregiverIdToApplications;
