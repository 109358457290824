import { Button, Card, CardContent, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import { selectedSourceAtom } from '../../recoil/atoms';
import { useSetRecoilState } from 'recoil';

const SourceCard = ({ source, setOpen }) => {
	const setSelectedSource = useSetRecoilState(selectedSourceAtom);

	const handleEdit = () => {
		setOpen(true);
		setSelectedSource(source);
	};

	return (
		<>
			<Container sx={{ mt: 1 }}>
				<Card variant='outlined'>
					<CardContent
						diplay='flex'
						justifyContent='space-between'
					>
						<Typography
							variant='body1'
							component='h5'
						>
							{source.name}
							<Button
								variant='contained'
								style={{ float: 'right' }}
								onClick={handleEdit}
							>
								Edit
							</Button>
						</Typography>
					</CardContent>
				</Card>
			</Container>
		</>
	);
};

export default SourceCard;
