import React, { useEffect, useState } from 'react';
import { Alert, Grid } from '@mui/material';
import AddReferralSource from './AddReferralSource';
import ReferralSourceList from './ReferralSourceList';
import { COLLECTIONS } from '../../libraries/objectsAndArrays';
import { blankSourceForm } from '../../shared/libraries/objects';
import useAddDoc from '../../hooks/useAddDoc';
import useGetDocs from '../../hooks/useGetDocs';
import SourceDialog from './SourceDialog';

const ReferralSourceContainer = () => {
	const [sourceFormSubmitted, setSourceFormSubmitted] = useState(false);
	const { sendRequest: addDoc } = useAddDoc();
	const [sourceForm, setSourceForm] = useState(blankSourceForm);
	const { sendRequest: getDocs } = useGetDocs();
	const [sources, setSources] = useState();
	const [open, setOpen] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		await addDoc({
			col: COLLECTIONS.referrals,
			data: {
				...sourceForm,
			},
		});
		setSourceForm(blankSourceForm);
		setSourceFormSubmitted(true);
	};

	useEffect(() => {
		const getReferralSources = async () => {
			const [arr] = await getDocs({ col: 'referralSources' });
			setSources(arr);
		};
		getReferralSources();
	}, [getDocs]);

	const handleAlertReset = () => {
		setSourceFormSubmitted(false);
	};

	return (
		<>
			<Grid
				container
				spacing={3}
			>
				<Grid
					item
					xs={6}
				>
					<AddReferralSource
						handleSubmit={handleSubmit}
						setSourceForm={setSourceForm}
					/>
				</Grid>
				<Grid
					itme
					xs={6}
					mt={2}
					style={{ padding: 8, overflow: 'scroll' }}
				>
					<ReferralSourceList
						sources={sources}
						setOpen={setOpen}
					/>
				</Grid>
			</Grid>
			{sourceFormSubmitted && (
				<Alert
					sx={{ mt: 1, mb: 2 }}
					severity='success'
					onClose={handleAlertReset}
				>
					Referral Addeded Successfully!
				</Alert>
			)}
			<SourceDialog
				open={open}
				setOpen={setOpen}
			/>
		</>
	);
};
export default ReferralSourceContainer;
