import React from "react";
import { useSetRecoilState } from "recoil";
import { leadNotesResetAtom } from "../../recoil/atoms";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useUpdateDoc from "../../hooks/useUpdateDoc";

export default function DeleteLeadConfirmation({ open, setOpen, deleteLeadId }) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setLeadNotesReset = useSetRecoilState(leadNotesResetAtom);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    await updateDoc({ col: "leads", id: deleteLeadId, data: { active: false } });
    setLeadNotesReset((pV) => !pV);
    handleClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure you want to delete this Lead?</DialogTitle>
        <DialogContent>
          <DialogContentText>This action can not be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
