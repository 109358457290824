import React from "react";
import { GridToolbar, DataGridPro } from "@mui/x-data-grid-pro";
import { columns } from "../../libraries/columns";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey(process.env.REACT_APP_DATATABLE_KEY);

export default function AppManagerTable({ filteredApps, onRowClick }) {

  return (
    <>
      {filteredApps && (
        <div style={{ height: 500, width: "100%" }} id="app-manager-table">
          <p>Application Gathering Place</p>
          <div
            style={{
              display: "flex",
              height: "100%",
            }}
          >
            <div style={{ flexGrow: 1, maxWidth: "100%" }}>
              <DataGridPro
                rows={filteredApps}
                columns={columns}
                rowHeight={32}
                onRowClick={(data) => onRowClick(data.row)}
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
