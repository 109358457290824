import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  appsAtom,
  appsResetAtom,
  appStatusesAtom,
  filteredAppStatusIdsAtom,
  leadsAtom,
  leadsResetAtom,
  staffAtom,
  staffObjAtom,
  staffResetAtom,
  leadNotesAtom,
  leadNotesResetAtom,
  currentStaffAtom,
} from "../recoil/atoms";
import useGetDocs from "./useGetDocs";
import { COLLECTIONS } from "../libraries/objectsAndArrays";
import { parseApplicationsResponse, parseApplicationStatusesResponse } from "../libraries/parsers";

const filterDefaultApplicationStatuses = (statuses) => {
  const filteredStatuses = statuses.filter((status) => status.default);
  return filteredStatuses.map((status) => status.id);
};

const useBootstrapEffect = () => {
  const setApps = useSetRecoilState(appsAtom);
  const setAppStatuses = useSetRecoilState(appStatusesAtom);
  const setFilteredAppStatusIds = useSetRecoilState(filteredAppStatusIdsAtom);
  const setLeads = useSetRecoilState(leadsAtom);
  const setLeadNotes = useSetRecoilState(leadNotesAtom);
  const setStaff = useSetRecoilState(staffAtom);
  const setStaffObj = useSetRecoilState(staffObjAtom);
  const currentStaff = useRecoilValue(currentStaffAtom);

  const appsReset = useRecoilValue(appsResetAtom);
  const leadsReset = useRecoilValue(leadsResetAtom);
  const leadNotesReset = useRecoilValue(leadNotesResetAtom);
  const staffReset = useRecoilValue(staffResetAtom);

  const { sendRequest: getDocs } = useGetDocs();
  const [buffer, setBuffer] = useState(1);

  useEffect(() => {
    if (!currentStaff || buffer !== 1) return;
    const getApps = async () => {
      const [response] = await getDocs({ col: COLLECTIONS.applications });
      setApps(parseApplicationsResponse(response));
      setBuffer((pV) => pV + 1);
    };
    getApps();
  }, [appsReset, setApps, getDocs, currentStaff, buffer]);

  useEffect(() => {
    const getApplicationStatuses = async () => {
      const [response] = await getDocs({ col: COLLECTIONS.statuses });
      const parsedResponse = parseApplicationStatusesResponse(response);
      const defaultStatusesArray = filterDefaultApplicationStatuses(response);
      setFilteredAppStatusIds(defaultStatusesArray);
      setAppStatuses(parsedResponse);
    };
    getApplicationStatuses();
  }, [setAppStatuses, getDocs, setFilteredAppStatusIds]);

  useEffect(() => {
    const getLeadNotes = async () => {
      const [arr] = await getDocs({ col: "leadNotes" });
      setLeadNotes(arr);
    };
    getLeadNotes();
  }, [getDocs, setLeadNotes, leadNotesReset]);

  useEffect(() => {
    if (!currentStaff || buffer !== 2) return;
    const getLeads = async () => {
      const [arr] = await getDocs({ col: "leads" });
      setLeads(arr);
      setBuffer((pV) => pV + 1);
    };
    getLeads();
  }, [setLeads, getDocs, leadsReset, currentStaff, buffer]);

  useEffect(() => {
    const getStaff = async () => {
      const [arr, obj] = await getDocs({ col: "staff" });
      setStaff(arr);
      setStaffObj(obj);
    };
    getStaff();
  }, [setStaff, setStaffObj, getDocs, staffReset]);
};

export default useBootstrapEffect;
