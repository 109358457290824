import React, { useState } from 'react';
import {
	Select,
	FormControl,
	InputLabel,
	MenuItem,
	Button,
	Grid,
} from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { staffAtom, leadsResetAtom } from '../../recoil/atoms';
import useUpdateDoc from '../../hooks/useUpdateDoc';
import BulkAssignCounter from './BulkAssignCounter';

export default function BulkAssignPanel({
	bulkCounter,
	bulkSelectedLeads,
	setBulkAssignEnabled,
	setBulkSelectedLeads,
}) {
	const staff = useRecoilValue(staffAtom);
	const setLeadsReset = useSetRecoilState(leadsResetAtom);
	const [selectedOwner, setSelectedOwner] = useState('No Owner');
	const { sendRequest: updateDoc } = useUpdateDoc();

	const handleChange = (e) => {
		setSelectedOwner(e.target.value);
	};

	const updateLead = async (lead) => {
		await updateDoc({ col: 'leads', id: lead, data: { owner: selectedOwner } });
	};

	const handleSubmit = () => {
		const promises = [];
		bulkSelectedLeads.forEach(async (lead) => {
			const promise = updateLead(lead);
			promises.push(promise);
		});
		Promise.all(promises).then(() => {
			setLeadsReset((pV) => !pV);
			setBulkAssignEnabled(false);
			setBulkSelectedLeads([]);
		});
	};

	return (
		<>
			<FormControl fullWidth>
				<Grid
					container
					spacing={2}
					alignItems='center'
				>
					<Grid
						item
						sm={8}
						xs={12}
					>
						<InputLabel id='contactMethodLabel'>Bulk Assign Owner</InputLabel>
						<Select
							labelId='ownerLabel'
							id='owner'
							name='owner'
							label='Bulk Assign Owner'
							value={selectedOwner}
							onChange={handleChange}
							fullWidth
						>
							<MenuItem
								key={'No Owner'}
								value={'No Owner'}
							>
								No Owner
							</MenuItem>
							{staff &&
								staff.map((staffMember) => (
									<MenuItem
										key={staffMember.id}
										value={staffMember.id}
									>
										{staffMember.firstName}
									</MenuItem>
								))}
						</Select>
					</Grid>
					<Grid
						item
						sm={4}
						xs={12}
					>
						<BulkAssignCounter bulkCounter={bulkCounter} />
					</Grid>
				</Grid>
			</FormControl>
			<Button
				onClick={handleSubmit}
				sx={{ mt: 2 }}
				fullWidth
				variant='contained'
			>
				Submit Bulk Assign
			</Button>
		</>
	);
}
