import React from "react";
import { CssBaseline, Container } from "@mui/material";
import NavBar from "../../shared/Navigation/Navbar";
import StaffContainer from "../components/StaffContainer";

export default function Staff() {
  return (
    <>
      <CssBaseline />
      <NavBar></NavBar>
      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: 8 }}>
        <StaffContainer />
      </Container>
    </>
  );
}
