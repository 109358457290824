import { Typography } from "@mui/material";

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <a color="primary" href="http://www.tgpk12.org">
        The Gathering Place
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
