import SourceCard from './SourceCard';
import { Paper } from '@mui/material';

const ReferralSourceList = ({ sources, setOpen }) => {
	return (
		<Paper sx={{ padding: 3, maxHeight: '325px', overflow: 'auto' }}>
			{sources &&
				sources.map((source) => (
					<SourceCard
						key={source.id}
						source={source}
						setOpen={setOpen}
					/>
				))}
		</Paper>
	);
};

export default ReferralSourceList;
