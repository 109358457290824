import React from "react";
import { Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import NavBar from "../shared/Navigation/Navbar";
import AppManagerContainer from "../components/AppManager/AppManagerContainer";

const AppManagerPage = () => {
  return (
    <>
      <CssBaseline />
      <NavBar></NavBar>
      <Container sx={{ minHeight: "100vh", mt: 8, pt: 2 }} maxWidth={"xl"}>
        <AppManagerContainer />
      </Container>
    </>
  );
};

export default AppManagerPage;
