import React from "react";
import { Card, CardContent, Typography, Grid, Avatar, CardHeader } from "@mui/material";
import SetSitePermissions from "./SetSitePermissions";

export default function StaffCard({ staff }) {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card>
        <CardHeader
          avatar={<Avatar sx={{ width: 70, height: 70 }} />}
          title={
            <Typography variant="cardTitle">
              {staff.firstName} {staff.lastName}
            </Typography>
          }
        />
        <CardContent>
          <SetSitePermissions staff={staff} />
        </CardContent>
      </Card>
    </Grid>
  );
}
