import { useState, useCallback } from "react";
import useGetDocs from "./useGetDocs";
import { COLLECTIONS } from "../libraries/objectsAndArrays";

const useGetCaregiver = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { sendRequest: getDocs } = useGetDocs();

  const getCaregiver = useCallback(
    async (email) => {
      setIsLoading(true);
      setError(null);
      try {
        const [resultsArray] = await getDocs({
          col: COLLECTIONS.caregivers,
          config: { where: ["email", "==", email.toLowerCase()] },
        });
        if (resultsArray.length === 0) {
          console.log("User with Logged In Email Not Found in db");
          return null;
        } else if (resultsArray.length > 1) {
          console.log("There are two users with the same email in the caregivers db");
          return null;
        } else {
          console.log("User Founde in Caregivers4");
          return resultsArray[0];
        }
      } catch (err) {
        setError(err.message || "Something went wrong!");
      }
      setIsLoading(false);
    },
    [getDocs]
  );
  return { isLoading, error, getCaregiver };
};

export default useGetCaregiver;
