import { DateTime } from "luxon";

export const removeNonNumbers = (phone) => {
  return phone.replace(/\D/g, "");
};

export const formatPhone = (phone) => {
  if (phone.length === 11) {
    return `${phone.slice(0, 1)} (${phone.slice(1, 4)}) ${phone.slice(4, 7)}-${phone.slice(7, 11)}`;
  } else if (phone.length === 10) {
    return `1 (${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
  } else {
    return phone;
  }
};

export const formatHasChild = (hasChild) => {
  return hasChild ? "Has a Child Entering K - 4" : "Does Not Have a Child Entering K - 4";
};

export const getSortedString = ({ leadsArray, field, sortDirection }) => {
  leadsArray.sort((a, b) =>
    a[field]?.toUpperCase() > b[field]?.toUpperCase()
      ? sortDirection
      : a[field]?.toUpperCase() === b[field]?.toUpperCase()
      ? a.id > b.id
        ? 1
        : -1
      : -1 * sortDirection
  );
  return leadsArray;
};

export const getSortedDate = ({ leadsArray, sortDirection }) => {
  leadsArray.sort((a, b) => {
    let unsortedDateOne = a.submittedOn;
    let unsortedDateTwo = b.submittedOn;
    if (unsortedDateOne.length < 7) {
      unsortedDateOne = unsortedDateOne + "/2022";
    }
    if (unsortedDateTwo.length < 7) {
      unsortedDateTwo = unsortedDateTwo + "/2022";
    }
    const dateOne = DateTime.fromFormat(unsortedDateOne, "D").toMillis();
    const dateTwo = DateTime.fromFormat(unsortedDateTwo, "D").toMillis();
    return dateOne > dateTwo
      ? sortDirection
      : dateOne === dateTwo
      ? a.lastName > b.lastName
        ? 1
        : -1
      : -1 * sortDirection;
  });
  return leadsArray;
};

export const formatAvatar = ({ staffObj, staffId }) => {
  if (staffObj[staffId]) return staffObj[staffId].firstName.slice(0, 1);
  return "";
};

export const formatName = ({ staffObj, staffId }) => {
  if (staffObj[staffId]) return `${staffObj[staffId].firstName} ${staffObj[staffId].lastName}`;
  return "No Owner";
};

export const sortTimestampByKey = ({ array, key }) => {
  array.sort((a, b) => (a[key]?.toMillis() > b[key]?.toMillis() ? -1 : 1));
  return array;
};

export const sortStringByKey = ({ array, key }) => {
  array.sort((a, b) => (a[key]?.toUpperCase() > b[key]?.toUpperCase() ? 1 : -1));
  return array;
};
