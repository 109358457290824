import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { selectedAppAtom, appsResetAtom } from "../../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useUpdateDoc from "../../hooks/useUpdateDoc";

export default function DeleteApp({ setAppDialogOpen }) {
  const [open, setOpen] = useState(false);
  const setAppsReset = useSetRecoilState(appsResetAtom);
  const selectedApp = useRecoilValue(selectedAppAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();

  const deactivateApp = async () => {
    await updateDoc({ col: "applications", id: selectedApp.id, data: { active: false } });
    setAppsReset((pV) => !pV);
    setOpen(false);
    setAppDialogOpen(false);
  };

  return (
    <div>
      <Button
        sx={{ mt: 2 }}
        fullWidth
        variant="contained"
        color="error"
        onClick={() => setOpen(true)}
      >
        Delete Application
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Application"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this application? If you do, you will not be able to
            recover it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Nevermind</Button>
          <Button color="error" onClick={deactivateApp} autoFocus>
            Yes, Delete The Application
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
