import { useRecoilValue } from "recoil";
import { filteredAppsAtom } from "../../recoil/atoms";
import AppCard from "./AppCard";

export default function CardContainer({ onRowClick }) {
  const filteredApps = useRecoilValue(filteredAppsAtom);

  return (
    <>
      {filteredApps &&
        filteredApps.map((app) => <AppCard app={app} key={app.id} onRowClick={onRowClick} />)}
    </>
  );
}
