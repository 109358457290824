import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";

export default function SetPasswordDialog({ open, setOpen }) {
  const { updateUserPassword, setCurrentUser } = useAuth();
  const [error, setError] = useState(false);
  const [tryToSave, setTryToSave] = useState(false);
  const [password, setPassword] = useState("");

  const savePassword = () => {
    setTryToSave(true);
    const validationError = validatePassword(password);
    setError(validationError);
    if (!validationError) {
      updateUserPassword(password);
      setCurrentUser((prevValue)=> ({...prevValue, tempPassword: false}))
      setOpen(false);
    }
  };

  const updatePassword = (e) => {
    setPassword(e.target.value);
    if (tryToSave) {
      setError(validatePassword(e.target.value));
    }
  };

  const validatePassword = (text) => {
    if (text.length < 10) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Dialog open={open}>
      <DialogTitle sx={{ fontSize: 22 }}>Create Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Thank you for submitting an application! You can now come back to this page anytime to
          edit your information, apply for additional children, and track the progress of your
          applications. First, we need you to set your password here:
        </DialogContentText>
        <TextField
          autoFocus
          id="name"
          label="Set Your Password"
          type="password"
          fullWidth
          error={error}
          onChange={(e) => updatePassword(e)}
          variant="standard"
          helperText={error ? "Your Password Must Be at Least 10 Characters" : null}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={savePassword}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
