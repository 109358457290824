import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#26C6F5",
        contrastText: "#fff",
      },
      secondary: {
        main: "#B930E6",
      },
      error: {
        main: "#EB3D3D",
      },
      gray: {
        main: "#ccc",
        contrastText: "#000"
      },
      lightGray: {
        main: "#eee",
        contrastText: "#000"
      },
    },
    typography: {
      fontFamily: "Urbanist, Arial",
      h2: {
        fontSize: 26,
      },
      h5: {
        fontSize: 24,
        fontWeight: 800,
        color: "#26C6F5",
      },
      h6: {
        fontSize: 14,
        fontWeight: 800,
      },
      body1: {
        fontSize: 18,
        fontWeight: 300,
      },
      leadCardTitle: {
        fontSize: 22,
        fontWeight: 700,
        display: "block",
      },
      leadCardDate: {
        fontSize: 18,
        fontWeight: 700,
        display: "block",
      },
      leadCardInfo: {
        fontSize: 18,
        fontWeight: 300,
        display: "block",
      },
      subtitle1: {
        fontSize: 12,
        color: "#fff",
      },
      labelText: {
        fontSize: 16,
        color: "#666",
      },
      cardEntry: {
        fontSize: 20,
        display: "block",
        fontFamily: "Urbanist, Arial",
      },
    },
  })
);
