import { Timestamp } from "firebase/firestore";

const timestamp = Timestamp.now();

export const SCHOOL_YEARS = {
  sy2021: "SY 20 - 21",
  sy2122: "SY 21 - 22",
  sy2223: "SY 22 - 23",
  sy2324: "SY 23 - 24",
  sy2425: "SY 24 - 25",
  sy2526: "SY 25 - 26",
  sy2627: "SY 26 - 27",
};

export const BLANK_APPLICATION = {
  assignedDistrict: "Unknown",
  birthday: "",
  applicationYear: SCHOOL_YEARS.sy2324,
  createdAt: timestamp,
  status: "Pending",
  statusId: "6fVWWlC7K6emVPSdsm0h",
  accountOwner: "Liz",
  tourRequested: "Not Asked",
  active: true,
  primaryCaregiverId: "",
  childFirstName: "",
};

export const CURRENT_GRADES = [
  "Kindergarten",
  "1st Grade",
  "2nd Grade",
  "3rd Grade",
  "4th Grade",
  "5th Grade",
  "6th Grade",
  "7th Grade",
];

export const NEXT_YEAR_GRADES = [
  "Kindergarten",
  "1st Grade",
  "2nd Grade",
  "3rd Grade",
  "4th Grade",
  "5th Grade",
  "6th Grade",
  "7th Grade",
];

export const COLLECTIONS = {
  applications: "applications",
  caregivers: "caregivers2",
  statuses: "statuses",
  staff: "staff",
  mail: "mail",
  leads: "leads",
  leadNotes: "leadNotes",
  comments: "comments",
  referrals: "referralSources",
};

export const NEW_APP_EMAIL = {
  to: "ryan@thegatheringplacek12.org",
  message: {
    subject: "A New Application Has Been Submitted",
    html: 'To view the application, please <a href="https://applications.tgpapps.org/applicationmanager">click here<a>.',
  },
};

export const PREFILLED_APPLICATION = {
  caregiverFirstName: "Jane",
  caregiverLastName: "Doe",
  childFirstName: "John",
  childLastName: "Doe",
  address: "123 Gathering Place Lane",
  city: "San Antonio",
  state: "Texas",
  zip: "78238",
  phone: "+1 (555) 555-5555",
  birthday: "2017-01-01",
  applicationYear: "SY 23 - 24",
  applicationGrade: "Kindergarten",
  email: "testEmail@testing123.com",
};
