import { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { COLLECTIONS } from "../../libraries/objectsAndArrays";
import { appsResetAtom, selectedAppAtom } from "../../recoil/atoms";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { Timestamp } from "firebase/firestore";

export default function EditCommentDialog({ open, setOpen, selectedComment, setResetComments }) {
  const [editedComment, setEditedComment] = useState();
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setAppsReset = useSetRecoilState(appsResetAtom);
  const selectedApp = useRecoilValue(selectedAppAtom);

  const saveComment = async () => {
    await updateDoc({
      col: COLLECTIONS.comments,
      id: selectedComment.id,
      data: { comment: editedComment },
    });
    await updateDoc({
      col: COLLECTIONS.applications,
      id: selectedApp.id,
      data: { lastComment: Timestamp.now() },
    });
    setResetComments((pV) => !pV);
    setAppsReset((pV) => !pV);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {selectedComment && (
        <Dialog open={open}>
          <DialogTitle>Edit Comment</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              id="comment"
              label="Comment"
              type="text"
              fullWidth
              multiline
              defaultValue={selectedComment.comment}
              variant="standard"
              onChange={(e) => setEditedComment(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={saveComment}>Save</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
