import { CircularProgress, Backdrop } from "@mui/material";
import NavBar from "../shared/Navigation/Navbar";
import ApplicationContainer from "../components/Application/ApplicationContainer";
import { useAuth } from "../contexts/AuthContext";

const ApplicationPage = () => {
  const { loading } = useAuth();
  return (
    <>
      {loading ? (
        <Backdrop open={loading}>
          <CircularProgress />
        </Backdrop>
      ) : (
        <>
          <NavBar></NavBar>
          <ApplicationContainer />
        </>
      )}
    </>
  );
};

export default ApplicationPage;
