import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FamilyDashboardPage from './pages/FamilyDashboardPage';
import PrivateStaffRoute from './PrivateStaffRoute';
import PrivateCaregiverRoute from './PrivateCaregiverRoute';
import AppManagerPage from './pages/AppManagerPage';
import LeadForm from './LeadForm/pages/LeadForm';
import LeadUpload from './LeadUpload/pages/LeadUpload';
import LeadManager from './LeadManager/pages/LeadManager';
import Staff from './Staff/pages/Staff';
import ApplicationPage from './pages/ApplicationPage';
import { SITE_PERMISSIONS_OBJ } from './shared/libraries/objects';
import ConvertCaregiversToEmail from './dbchanges/ConvertCaregiversToEmail';
import AddCaregiverIdToApplications from './dbchanges/AddCaregiverIdToApplications';
import LoginPage from './pages/LoginPage';
import CreateUniformAppYearFields from './dbchanges/CreateUniformAppYearFields';
import AddActiveToCollection from './dbchanges/AddActiveToCollection';
import UpdateAppsToStatusIds from './dbchanges/UpdateAppsToStatusIds';

import SettingsPage from './pages/SettingsPage';

export const routes = (
	<Switch>
		<Route
			exact
			path='/'
			component={ApplicationPage}
		/>
		<Route
			exact
			path='/login'
			component={LoginPage}
		/>
		<Route
			exact
			path='/application'
			component={ApplicationPage}
		/>
		<PrivateCaregiverRoute
			exact
			path='/family-dashboard'
			component={FamilyDashboardPage}
		/>
		<PrivateStaffRoute
			exact
			path='/applicationmanager'
			component={AppManagerPage}
			permission={SITE_PERMISSIONS_OBJ.see_all_applications}
		/>
		<PrivateStaffRoute
			exact
			path='/settings'
			component={SettingsPage}
			// permission={SITE_PERMISSIONS_OBJ.see_all_applications}
		/>
		<PrivateStaffRoute
			exact
			path='/leadform'
			component={LeadForm}
		/>
		<PrivateStaffRoute
			exact
			path='/leadupload'
			component={LeadUpload}
			permission={SITE_PERMISSIONS_OBJ.upload_leads}
		/>
		<PrivateStaffRoute
			exact
			path='/leadmanager'
			component={LeadManager}
		/>
		<PrivateStaffRoute
			exact
			path='/staff'
			component={Staff}
			permission={SITE_PERMISSIONS_OBJ.edit_staff}
		/>

		<PrivateStaffRoute
			exact
			path='/update-statuses'
			component={UpdateAppsToStatusIds}
			permission={SITE_PERMISSIONS_OBJ.edit_staff}
		/>
		<PrivateStaffRoute
			exact
			path='/add-active-to-collection'
			component={AddActiveToCollection}
			permission={SITE_PERMISSIONS_OBJ.edit_staff}
		/>
		<PrivateStaffRoute
			exact
			path='/update-app-year'
			component={CreateUniformAppYearFields}
			permission={SITE_PERMISSIONS_OBJ.edit_staff}
		/>
		<PrivateStaffRoute
			exact
			path='/convert-caregivers'
			component={ConvertCaregiversToEmail}
			permission={SITE_PERMISSIONS_OBJ.edit_staff}
		/>
		<PrivateStaffRoute
			exact
			path='/convert-applications'
			component={AddCaregiverIdToApplications}
			permission={SITE_PERMISSIONS_OBJ.edit_staff}
		/>
	</Switch>
);
