import { getSortedString, getSortedDate } from '../shared/libraries/util';

export const filterByName = ({ leadsArray, leadFilteredSearch }) => {
	const keys = ['firstName', 'lastName', 'email'];
	if (leadFilteredSearch.length > 2) {
		return leadsArray.filter((lead) =>
			keys.some((key) => lead[key].toLowerCase().includes(leadFilteredSearch))
		);
	} else {
		return leadsArray;
	}
};

export const filterByPermissions = ({
	currentStaff,
	SITE_PERMISSIONS_OBJ,
	leadsArray,
}) => {
	if (
		!currentStaff.sitePermissions.includes(SITE_PERMISSIONS_OBJ.see_all_leads)
	) {
		return leadsArray.filter((lead) => lead.owner === currentStaff.email);
	} else {
		return leadsArray;
	}
};

export const filterByStatus = ({ leadsArray, leadFilteredStatuses }) => {
	return leadsArray.filter((lead) =>
		leadFilteredStatuses.includes(lead.status)
	);
};

export const filterByOwner = ({ leadsArray, leadFilteredStaff }) => {
	return leadsArray.filter((lead) => leadFilteredStaff.includes(lead.owner));
};

export const sortLeads = ({
	leadSortField,
	leads,
	leadSortDirection,
	leadsArray,
}) => {
	if (!leadSortField) {
		return leads;
	} else if (leadSortField === 'submittedOn') {
		const sortDirection = leadSortDirection.submittedOn ? -1 : 1;
		const sorted = getSortedDate({ leadsArray, sortDirection });
		return sorted;
	} else {
		const sortDirection = leadSortDirection[leadSortField] ? -1 : 1;
		const sorted = getSortedString({
			leadsArray,
			field: leadSortField,
			sortDirection,
		});
		return sorted;
	}
};
