import React, { useState, useEffect } from 'react';
import { Paper, Typography, Chip, Avatar, Box } from '@mui/material';
import { useRecoilValue, useRecoilState } from 'recoil';
import { staffObjAtom, selectedLeadAtom } from '../../recoil/atoms';
import {
	formatHasChild,
	formatAvatar,
	formatName,
} from '../../shared/libraries/util';
import { LEAD_STATUSES } from '../../shared/libraries/objects';

export default function LeadCard({
	lead,
	setBulkSelectedLeads,
	bulkAssignEnabled,
	bulkSelectedLeads,
	setBulkCounter,
}) {
	const [selectedLead, setSelectedLead] = useRecoilState(selectedLeadAtom);
	const [cardClass, setCardClass] = useState('leadCardNotSelected');
	const staffObj = useRecoilValue(staffObjAtom);

	const handleClick = () => {
		if (bulkAssignEnabled) {
			const currentBulkSelected = [...bulkSelectedLeads];
			if (currentBulkSelected.includes(lead.id)) {
				currentBulkSelected.splice(currentBulkSelected.indexOf(lead.id), 1);
			} else {
				currentBulkSelected.push(lead.id);
			}
			setBulkSelectedLeads(currentBulkSelected);
			setBulkCounter(currentBulkSelected.length);
			console.log(currentBulkSelected);
		} else {
			setSelectedLead(lead);
		}
	};

	const formatChipColor = ({ staffObj, staffId }) => {
		if (staffObj[staffId]) return 'primary';
		return 'gray';
	};

	useEffect(() => {
		if (
			selectedLead?.id === lead?.id ||
			bulkSelectedLeads?.includes(lead?.id)
		) {
			setCardClass('leadCardSelected');
		} else {
			setCardClass('leadCardNotSelected');
		}
	}, [selectedLead, lead, bulkSelectedLeads]);
	return (
		<Paper
			onClick={handleClick}
			sx={{
				mt: 1,
				padding: 2,
				display: 'flex',
				justifyContent: 'space-between',
				cursor: 'pointer',
			}}
			className={cardClass}
		>
			<Box>
				<Typography variant='leadCardTitle'>{`${lead.firstName} ${lead.lastName}`}</Typography>
				<Typography variant='leadCardDate'>{lead.submittedOn}</Typography>
				<Typography variant='leadCardInfo'>
					{formatHasChild(lead.hasChild)}
				</Typography>
			</Box>
			<Box>
				<Box>
					<Box sx={{ mt: 0, textAlign: 'right' }}>
						<Chip
							color={formatChipColor({ staffObj, staffId: lead.owner || '' })}
							avatar={
								<Avatar>
									{formatAvatar({ staffObj, staffId: lead.owner || '' })}
								</Avatar>
							}
							label={formatName({ staffObj, staffId: lead.owner || '' })}
						/>
					</Box>
					<Box sx={{ mt: 3, textAlign: 'right' }}>
						<Chip
							sx={{
								backgroundColor: LEAD_STATUSES[lead.status].color || '#eee',
							}}
							label={LEAD_STATUSES[lead.status].name || 'No Status'}
						/>
					</Box>
				</Box>
			</Box>
		</Paper>
	);
}
