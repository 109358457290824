import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Typography } from '@mui/material';
import useGetDocs from '../../hooks/useGetDocs';

function ReferralSelect({ setForm }) {
	const [sources, setSources] = useState();
	const { sendRequest: getDocs } = useGetDocs();

	useEffect(() => {
		const getReferralSources = async () => {
			const [arr] = await getDocs({ col: 'referralSources' });
			setSources(arr);
		};
		getReferralSources();
	}, [getDocs]);

	const handleChange = (event) => {
		setForm((prevValue) => ({
			...prevValue,
			referralSource: event.target.value,
		}));
	};
	return (
		<>
			<Typography
				variant='body1'
				style={{ marginTop: '1em' }}
			>
				How did you hear about us?
			</Typography>
			<Select
				labelId='referralSource'
				id='referralSource'
				name='referralSource'
				defaultValue=''
				style={{ marginBottom: '1em', marginTop: '1em' }}
				onChange={handleChange}
				fullWidth
			>
				{sources &&
					sources.map((item) => (
						<MenuItem
							key={item.id}
							value={item.name}
						>
							{item.name}
						</MenuItem>
					))}
			</Select>
		</>
	);
}

export default ReferralSelect;
