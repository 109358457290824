import React, { useState } from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { CURRENT_GRADES, PREFILLED_APPLICATION } from "../../libraries/objectsAndArrays";
import Phone from "./Phone";
import FormTextField from "./FormTextField";
import ReferralSelect from "./ReferralSelect";
import GradeSelect from "./GradeSelect";
import Birthday from "./Birthday";
import ApplicationYearSelect from "./ApplicationYearSelect";
import Email from "./Email";
import AnythingElse from "./AnythingElse";

export default function ApplicationForm({ submit, setForm, checked, form }) {
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [grades, setGrades] = useState(CURRENT_GRADES);
  const { currentUser } = useAuth();

  const testData = { ...PREFILLED_APPLICATION };

  const sendToPhone = () => {
    const phoneEl = document.getElementById("phone");
    phoneEl.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
    setPhoneTouched(true);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (phoneVerified || currentUser) {
      submit();
    } else {
      sendToPhone();
    }
  };

  const handleTextChange = (event) => {
    setForm((prevValue) => ({
      ...prevValue,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {!currentUser && (
            <Box sx={{ backgroundColor: "#F6E649", borderRadius: "5px" }}>
              <Typography sx={{ mt: 2, mb: 2, textAlign: "center" }} variant="body1">
                If you have already filled out an application for one child here, please click Login
                above to log into your account to apply for additional children.
              </Typography>
            </Box>
          )}
        </Grid>
        {/* <Grid item sm={12} xs={12}>
          <ApplicationYearSelect setForm={setForm} setGrades={setGrades} />
        </Grid> */}
        {!currentUser && (
          <React.Fragment>
            <Grid item sm={6} xs={12}>
              <FormTextField
                label="Primary Caregiver's First Name*"
                handleChange={handleTextChange}
                name="caregiverFirstName"
                required={true}
                value={checked ? testData.caregiverFirstName : form.caregiverFirstName}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormTextField
                label="Primary Caregiver's Last Name*"
                handleChange={handleTextChange}
                name="caregiverLastName"
                required={true}
                value={checked ? testData.caregiverLastName : form.caregiverLastName}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Phone
                setForm={setForm}
                setPhoneTouched={setPhoneTouched}
                phoneTouched={phoneTouched}
                phoneVerified={phoneVerified}
                setPhoneVerified={setPhoneVerified}
                checked={checked}
                testData={testData}
                form={form}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Email setForm={setForm} checked={checked} testData={testData} form={form} />
            </Grid>
          </React.Fragment>
        )}
        <Grid item sm={6} xs={12}>
          <FormTextField
            label="Child's First Name*"
            handleChange={checked ? null : handleTextChange}
            name="childFirstName"
            required={true}
            value={checked ? testData.childFirstName : form.childFirstName}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormTextField
            label="Child's Last Name*"
            handleChange={handleTextChange}
            name="childLastName"
            required={true}
            value={checked ? testData.childLastName : form.childLastName}
          />
        </Grid>
        <Birthday setForm={setForm} checked={checked} testData={testData} form={form} />
        <Grid item sm={6} xs={12}>
          <GradeSelect
            setForm={setForm}
            grades={grades}
            checked={checked}
            testData={testData}
            form={form}
          />
        </Grid>
        <Grid item sm={8} xs={12}>
          <FormTextField
            label="Street Address*"
            handleChange={handleTextChange}
            name="address"
            required={true}
            value={checked ? testData.address : form.address}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormTextField
            label="Apartment #"
            handleChange={handleTextChange}
            name="aptNum"
            required={false}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormTextField
            label="City*"
            handleChange={handleTextChange}
            name="city"
            required={true}
            value={checked ? testData.city : form.city}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormTextField
            label="State*"
            handleChange={handleTextChange}
            name="state"
            required={true}
            value={checked ? testData.state : form.state}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormTextField
            label="Zip Code*"
            handleChange={handleTextChange}
            name="zip"
            required={true}
            value={checked ? testData.zip : form.zip}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ReferralSelect setForm={setForm} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormTextField
            label="If you were referred by someone, who:"
            handleChange={handleTextChange}
            name="referral"
            required={false}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormTextField
            label="If you already have a child at TGP, list their name(s) here:"
            handleChange={handleTextChange}
            name="existingSiblings"
            required={false}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormTextField
            label="If you are applying for other children, list their name(s) here:"
            handleChange={handleTextChange}
            name="sibling"
            required={false}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <AnythingElse handleChange={handleTextChange} />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            style={{ marginBottom: "1em", marginTop: "1em" }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
