import { useState } from 'react';
import { Typography, TextField, Alert } from '@mui/material';
import { validateEmail } from '../../libraries/functions';

function Email({ setForm, checked, testData, form }) {
	const [duplicate, setDuplicate] = useState(false);

	const handleEmailCheck = async (e) => {
		setDuplicate(false);
		const check = await validateEmail(e.target.value);
		setDuplicate(check);
	};

	const handleEmailChange = (e) => {
		setDuplicate(false);
		setForm((prevValue) => {
			return { ...prevValue, email: e.target.value };
		});
	};
	return (
		<>
			<Typography
				variant='body1'
				style={{ marginTop: '1em' }}
			>
				Primary Caregiver's Email*
			</Typography>
			<TextField
				fullWidth
				required
				style={{ marginTop: '1em' }}
				id='email'
				name='email'
				type='email'
				onChange={handleEmailChange}
				onBlur={handleEmailCheck}
				value={checked ? testData.email : form.email}
			/>
			{duplicate && (
				<Alert
					sx={{ mt: 2 }}
					severity='error'
				>
					A user with this email already exists, please click Login above and
					Add an additional application from there.
				</Alert>
			)}
		</>
	);
}

export default Email;
