import React, { useState } from "react";
import { Fab, Grid, Typography } from "@mui/material";
import { useSetRecoilState, useRecoilValue } from "recoil";
import AddIcon from "@mui/icons-material/Add";
import StaffCard from "./StaffCard";
import CreateStaffDialog from "./CreateStaffDialog";
import { staffAtom, staffObjAtom, staffResetAtom } from "../../recoil/atoms";

export default function StaffContainer() {
  const [createOpen, setCreateOpen] = useState(false);
  const staff = useRecoilValue(staffAtom);

  const handleNewStaff = () => {
    setCreateOpen(true);
  };

  return (
    <>
      <Typography variant="h1" sx={{ mt: 3 }}>
        Staff
      </Typography>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {staff && staff.map((s) => <StaffCard key={s.id} staff={s} />)}
      </Grid>
      <Fab
        sx={{ position: "fixed", bottom: "5vh", right: "5vw" }}
        color="primary"
        aria-label="add"
        onClick={() => handleNewStaff()}
      >
        <AddIcon />
      </Fab>
      <CreateStaffDialog open={createOpen} setOpen={setCreateOpen} />
    </>
  );
}
