import { useEffect } from "react";
import { Avatar, Divider, CircularProgress, Container, Box, Alert, Backdrop } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import CaregiverLogin from "./CaregiverLogin";
import StaffLogin from "./StaffLogin";
import Copyright from "./Copyright";

export default function LoginContainer() {
  const { loading, loginType, loginError, resetSuccess, setLoginError, setResetSuccess } =
    useAuth();

  useEffect(() => {
    setResetSuccess(null);
    setLoginError(null);
  }, [loginType, setResetSuccess, setLoginError]);

  return (
    <Container component="main" maxWidth="xs" sx={{ height: "100vh" }}>
      {loading ? (
        <Backdrop open={loading}>
          <CircularProgress size={100} />
        </Backdrop>
      ) : (
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "8em",
          }}
        >
          <Avatar sx={{ width: 200, height: 200 }} src="TGPicon.png" />
          {loginError && (
            <Alert sx={{ mt: 2, mb: 2 }} severity="error">
              {loginError}
            </Alert>
          )}
          {resetSuccess && (
            <Alert sx={{ mt: 2, mb: 2 }} severity="success">
              {resetSuccess}
            </Alert>
          )}
          {loginType === "caregiver" && <CaregiverLogin />}
          <Divider />
          {loginType === "staff" && <StaffLogin />}
          <Box sx={{ mt: 5 }}>
            <Copyright />
          </Box>
        </Box>
      )}
    </Container>
  );
}
