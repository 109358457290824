import { useState } from "react";
import { Paper, Typography, CircularProgress, Box, Container } from "@mui/material";
import ApplicationForm from "./ApplicationForm";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import useAddDoc from "../../hooks/useAddDoc";
import useGetCaregiver from "../../hooks/useGetCaregiver";
import { BLANK_APPLICATION, COLLECTIONS, NEW_APP_EMAIL } from "../../libraries/objectsAndArrays";
import { Timestamp } from "firebase/firestore";
import DevModeSwitch from "./DevModeSwitch";
import { SITE_PERMISSIONS_OBJ } from "../../shared/libraries/objects";
import { useRecoilValue } from "recoil";
import { currentStaffAtom } from "../../recoil/atoms";

export default function ApplicationContainer() {
  const currentStaff = useRecoilValue(currentStaffAtom);

  const [checked, setChecked] = useState(false);
  const { currentUser, createUser } = useAuth();
  const history = useHistory();

  const [form, setForm] = useState(BLANK_APPLICATION);
  const [submitLoading, setSubmitLoading] = useState(false);
  const timestamp = Timestamp.now();
  const { sendRequest: addDoc } = useAddDoc();
  const { getCaregiver } = useGetCaregiver();

  const submitWithCaregiverData = async (caregiver) => {
    try {
      await addDoc({
        col: COLLECTIONS.applications,
        data: {
          ...form,
          caregiverFirstName: caregiver.firstName,
          caregiverLastName: caregiver.lastName,
          phone: caregiver.phone,
          email: caregiver.email,
          caregiverId: caregiver.id,
        },
      });
    } catch (error) {
      console.error(
        `[APP SUBMISSION ERROR] Attept to add application while user is logged in failed for user ${caregiver.email} error message: `,
        error
      );
    }
  };

  const submitwithNoCurrentUser = async () => {
    const currentCaregiver = await getCaregiver(form.email);
    if (!currentCaregiver) {
      try {
        const caregiverId = await addDoc({
          col: COLLECTIONS.caregivers,
          data: {
            firstName: form.caregiverFirstName,
            lastName: form.caregiverLastName,
            email: form.email.toLowerCase(),
            phone: form.phone,
            createdAt: timestamp,
            tempPassword: true,
            primary: true,
            active: true,
          },
        });
        await addDoc({
          col: COLLECTIONS.applications,
          data: { ...form, caregiverId: caregiverId },
        });
      } catch (error) {
        console.error(
          `[APP SUBMISSION ERROR] Attept to add application for new user failed for ${form.email.toLowerCase()} error message: `,
          error
        );
      }
    } else {
      submitWithCaregiverData(currentCaregiver);
    }

    try {
      await createUser(form.email, "TempPassword");
    } catch (error) {
      console.error(
        `[AUTHENTICATION SUBMISSION ERROR] Attept to add new auth user for new user failed for ${form.email.toLowerCase()} error message: `,
        error
      );
    }
  };

  const submit = async () => {
    setSubmitLoading(true);
    if (currentUser) {
      await submitWithCaregiverData(currentUser);
    } else {
      await submitwithNoCurrentUser();
    }
    await addDoc({ col: COLLECTIONS.mail, data: NEW_APP_EMAIL });
    setSubmitLoading(false);
    history.push("/family-dashboard");
  };

  return (
    <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: 8 }}>
      <Typography sx={{ pt: 3, mb: 2, textAlign: "center" }} variant="h3">
        TGP Application
      </Typography>
      <Container maxWidth="md">
        <Typography sx={{ mt: 2, mb: 2, textAlign: "center" }} variant="body1">
          The Gathering Place is a free, first-of-its-kind free public school that nurtures each
          child’s creativity and critical thinking. Fill out the application below to reserve a seat
          and speak with a member of the school enrollment team for the current 2023 - 2024 school
          year. If you need any help filling out your application, please email
          ryan@thegatheringplacek12.org.
        </Typography>
      </Container>

      <Paper sx={{ padding: 2 }}>
        {submitLoading ? (
          <Box sx={{ display: "block", textAlign: "center" }}>
            <CircularProgress size={80} />
          </Box>
        ) : (
          <>
            <ApplicationForm submit={submit} form={form} setForm={setForm} checked={checked} />
          </>
        )}
      </Paper>
    </Container>
  );
}
