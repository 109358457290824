import React from "react";
import { useRecoilState } from "recoil";
import { leadFilteredStatusesAtom } from "../../recoil/atoms";
import { FormControlLabel, Box, Checkbox, Typography } from "@mui/material";
import { generateLeadStatusArray } from "../../shared/libraries/objects";
import {} from "../../recoil/atoms";

export default function FilterStatusSelect() {
  const leadStatuses = generateLeadStatusArray();
  const [leadFilteredStatuses, setLeadFilteredStatuses] = useRecoilState(leadFilteredStatusesAtom);

  const handleStatusChange = (event) => {
    const statusesArray = [...leadFilteredStatuses];
    if (statusesArray.indexOf(event.target.name) > -1) {
      statusesArray.splice(statusesArray.indexOf(event.target.name), 1);
    } else {
      statusesArray.push(event.target.name);
    }
    setLeadFilteredStatuses(statusesArray);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography variant="h6">Statuses: </Typography>
        {leadFilteredStatuses &&
          leadStatuses.map((status) => (
            <FormControlLabel
              key={status.key}
              control={
                <Checkbox
                  checked={leadFilteredStatuses.includes(status.key)}
                  onChange={handleStatusChange}
                  name={status.key}
                />
              }
              label={<Typography variant={"body2"}>{status.name}</Typography>}
            />
          ))}
      </Box>
    </>
  );
}
