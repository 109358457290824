import { Typography, Select, MenuItem } from '@mui/material';

function GradeSelect({ setForm, grades, checked, testData, form }) {
	const handleChange = (event) => {
		setForm((prevValue) => ({
			...prevValue,
			applicationGrade: event.target.value,
		}));
	};
	return (
		<>
			<Typography
				variant='body1'
				style={{ marginTop: '1em' }}
			>
				Grade Level Applying For*
			</Typography>
			<Select
				labelId='applicationGrade'
				id='applicationGrade'
				defaultValue=''
				style={{ marginBottom: '1em', marginTop: '1em' }}
				onChange={handleChange}
				fullWidth
				value={checked ? testData.applicationGrade : form.gradeSelect}
			>
				{grades.map((grade) => (
					<MenuItem
						key={grade}
						value={grade}
					>
						{grade}
					</MenuItem>
				))}
			</Select>
		</>
	);
}

export default GradeSelect;
