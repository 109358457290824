import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoginContainer from "../components/Login/LoginContainer";
import { useAuth } from "../contexts/AuthContext";

export default function LoginPage() {
  const { currentUser } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (currentUser && currentUser.access === "staff") {
      history.push("/applicationmanager");
    }
    if (currentUser && currentUser.access === "caregiver") {
      history.push("/family-dashboard");
    }
  }, [currentUser, history]);

  return <LoginContainer />;
}
