import { Card, CardContent, Typography, Fab, CardActions } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { renderDate } from "../../libraries/functions";

export default function Comment({ comment, setSelectedComment, setEditOpen, setDeleteOpen }) {
  const date = renderDate(comment);

  const handleEditCommentOpen = () => {
    setSelectedComment(comment);
    setEditOpen(true);
  };

  const handleDeleteCommentOpen = () => {
    setSelectedComment(comment);
    setDeleteOpen(true);
  };

  return (
    <>
      <Card sx={{ marginBottom: 2 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {comment.author}
          </Typography>
          <Typography gutterBottom variant="body2" component="div" color="text.primary">
            {date}
          </Typography>
          <Typography variant="body1" color="text.primary">
            {comment.comment}
          </Typography>
        </CardContent>
        <CardActions sx={{ display: "block", textAlign: "right" }}>
          <Fab size="small" color="secondary" aria-label="edit" onClick={handleEditCommentOpen}>
            <EditIcon />
          </Fab>
          <Fab size="small" color="error" aria-label="delete" onClick={handleDeleteCommentOpen}>
            <DeleteIcon />
          </Fab>
        </CardActions>
      </Card>
    </>
  );
}
