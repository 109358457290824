import React from "react";
import { useRecoilValue } from "recoil";
import { leadSortDirectionAtom, leadSortFieldAtom } from "../../recoil/atoms";
import { Button, Grid } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export default function FilterSort({ handleSort, bulkAssignEnabled, handleBulkAssign }) {
  const leadSortDirection = useRecoilValue(leadSortDirectionAtom);
  const leadSortField = useRecoilValue(leadSortFieldAtom);
  const spacing = 1;

  return (
    <Grid container spacing={spacing}>
      <Grid item xs={6} sm={2}>
        <Button
          sx={{ mr: spacing }}
          name="firstName"
          variant={leadSortField === "firstName" ? "contained" : "outlined"}
          onClick={handleSort}
          fullWidth
        >
          First Name
          {leadSortDirection.firstName ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        </Button>
      </Grid>
      <Grid item xs={6} sm={2}>
        <Button
          sx={{ mr: spacing }}
          name="lastName"
          variant={leadSortField === "lastName" ? "contained" : "outlined"}
          onClick={handleSort}
          fullWidth
        >
          Last Name
          {leadSortDirection.lastName ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        </Button>
      </Grid>
      <Grid item xs={6} sm={2}>
        <Button
          sx={{ mr: spacing }}
          name="owner"
          onClick={handleSort}
          variant={leadSortField === "owner" ? "contained" : "outlined"}
          fullWidth
        >
          Owner
          {leadSortDirection.owner ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        </Button>
      </Grid>
      <Grid item xs={6} sm={2}>
        <Button
          sx={{ mr: spacing }}
          name="submittedOn"
          variant={leadSortField === "submittedOn" ? "contained" : "outlined"}
          onClick={handleSort}
          fullWidth
        >
          Date
          {leadSortDirection.submittedOn ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        </Button>
      </Grid>
      <Grid item xs={6} sm={2}>
        <Button
          sx={{ mr: spacing }}
          name="status"
          variant={leadSortField === "status" ? "contained" : "outlined"}
          onClick={handleSort}
          fullWidth
        >
          Status
          {leadSortDirection.status ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        </Button>
      </Grid>
      <Grid item xs={6} sm={2}>
        <Button
          name="bulkAssign"
          variant={bulkAssignEnabled ? "contained" : "outlined"}
          color="secondary"
          onClick={handleBulkAssign}
          fullWidth
        >
          Bulk Assign
        </Button>
      </Grid>
    </Grid>
  );
}
