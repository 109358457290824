export const LEAD_CONTACT_METHODS = {
	text: 'Text',
	call: 'Call',
	email: 'Email',
};

export const LEAD_SOURCES = {
	tabling: 'Tabling',
	facebook: 'Facebook',
	website: 'Website',
	event: 'Event',
	coldCall: 'Cold Call',
	other: 'Other',
};

export const blankLeadForm = {
	firstName: '',
	lastName: '',
	phone: '',
	email: '',
	contactMethod: LEAD_CONTACT_METHODS.text,
	hasChild: true,
	source: LEAD_SOURCES.tabling,
	sourceNotes: '',
	owner: 'No Owner',
};

export const SITE_PERMISSIONS = [
	'Upload Leads',
	'See All Leads',
	'See All Applications',
	'Edit Staff',
];

export const SITE_PERMISSIONS_OBJ = {
	upload_leads: 'Upload Leads',
	see_all_leads: 'See All Leads',
	see_all_applications: 'See All Applications',
	edit_staff: 'Edit Staff',
};

export const LEAD_SORT_DIRECTION = {
	lastName: false,
	firstName: false,
	submittedOn: true,
	owner: false,
	status: false,
};

export const LEAD_STATUSES = {
	cold: { name: 'Cold', color: '#adf3ef' },
	newLead: { name: 'New Lead', color: '#fde096' },
	active: { name: 'Active', color: '#8cf5dd' },
	doNotContactAgain: { name: 'Do No Contact Again', color: '#FC5176' },
	filledOutApp: { name: 'Filled Out App', color: '#d1ebef' },
	scheduledTour: { name: 'Scheduled Tour', color: '#cef3b9' },
	nextYear: { name: 'Hold for Next School Year', color: '#f4e1de' },
	SY2425: { name: 'Hold for the 24 - 25 School Year', color: '#cef3b9d' },
};

export const generateLeadStatusArray = () => {
	const tempArray = [];
	const keys = Object.keys(LEAD_STATUSES);
	keys.forEach((key) => {
		tempArray.push({ ...LEAD_STATUSES[key], key: key });
	});
	return tempArray;
};

export const blankSourceForm = {
	name: '',
	author: '',
	description: '',
};

// #adf3ef - cold
// #fde096 - new  lead
// #8cf5dd - active
// #FC5176 - do not call again
// #d1ebef - filled out app
// #cef3b9 - scheduledTour
// #f4e1de - nextYear
// #cef3b9 - Two Years OUt
// #d3f1d6
// #eaeac1
