import { useState } from "react";
import { Typography, Grid, TextField, Alert } from "@mui/material";
import { birthdayCheck } from "../../libraries/functions";

function Birthday({ setForm, checked, testData, form }) {
  const [birthdayError, setBirthdayError] = useState();

  const handleBirthdayChange = (event) => {
    const message = birthdayCheck(event.target.value);
    setBirthdayError(message);
    setForm((prevValue) => ({ ...prevValue, birthday: event.target.value }));
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography sx={{ mt: 2, mb: 2, textAlign: "center" }} variant="body2">
          A child must be 5 years old on or before September 1st of this year to enroll in
          Kindergarten.
        </Typography>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Typography variant="body1" style={{ marginTop: "1em" }}>
          Child's Birthday*
        </Typography>
        <TextField
          fullWidth
          required
          type="date"
          format="YYYY-MM-DD"
          style={{ marginTop: "1em" }}
          id="childBirthday"
          name="childBirthday"
          onChange={handleBirthdayChange}
          value={checked ? testData.birthday : form.birthday}
        />
        {birthdayError && <Alert severity="error">{birthdayError}</Alert>}
      </Grid>
    </>
  );
}

export default Birthday;
