import { useEffect } from "react";
import { db } from "../firebase";
import { doc, getDocs, collection, updateDoc } from "firebase/firestore";
import { COLLECTIONS } from "../libraries/objectsAndArrays";

const COLLECTION = COLLECTIONS.caregivers;


const AddActiveToCollection = () => {
  useEffect(() => {
    const getDocuments = async () => {
      const ref = collection(db, COLLECTION);
      const snapshot = await getDocs(ref);
      snapshot.forEach((row) => {
        const docRef = doc(db, COLLECTION, row.id);
        console.log(row.data())
        if (row.data().active !== false) {
          updateDoc(docRef, { active: true });
        }
      });
    };
    getDocuments();
  }, []);

  return null;
};

export default AddActiveToCollection;
