import { Button, Divider, Grid, TextField } from '@mui/material';
import React from 'react';

const EditReferralForm = ({
	handleSubmit,
	handleDelete,
	form,
	setForm,
	selectedSource,
}) => {
	const handleChange = (e) => {
		setForm((previousValue) => ({
			...previousValue,
			[e.target.name]: e.target.value,
		}));
	};

	return (
		<form
			onSubmit={handleSubmit}
			style={{ margin: 8 }}
		>
			<Grid
				container
				spacing={3}
			>
				<Grid
					item
					sm={6}
					xs={12}
				>
					<TextField
						id='name'
						name='name'
						label='Referral Source Name'
						variant='outlined'
						required
						fullWidth
						value={form.name}
						onChange={handleChange}
					/>
				</Grid>
				<Grid
					item
					sm={6}
					xs={12}
				>
					<TextField
						id='author'
						name='author'
						label='Added By'
						variant='outlined'
						required
						fullWidth
						value={form.author}
						onChange={handleChange}
					/>
				</Grid>
				<Grid
					container
					spacing={2}
					sx={{ mt: 2, ml: 1 }}
				>
					<Grid
						item
						sm={12}
						xs={12}
					>
						<TextField
							id='description'
							name='description'
							label='Brief Description'
							variant='outlined'
							required
							fullWidth
							value={form.description}
							onChange={handleChange}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Button
				sx={{ mt: 2 }}
				type='submit'
				variant='contained'
				fullWidth
				onClick={handleSubmit}
			>
				Submit
			</Button>
			<Divider sx={{ mt: 2 }} />
			<Button
				sx={{ mt: 2 }}
				variant='contained'
				color='error'
				fullWidth
				onClick={() => handleDelete(selectedSource.id)}
			>
				Delete Source
			</Button>
		</form>
	);
};

export default EditReferralForm;
