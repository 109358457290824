import { useState, useEffect } from "react";

export default function useWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  const [collapse, setCollapse] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({ width: width, height: height });

  const handleResize = () => {
    const { innerWidth: width } = window;
    if (width > 1000) {
      setCollapse(false);
    }

    if (width <= 1000) {
      setCollapse(true);
    }
  };

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { windowDimensions, collapse };
}
