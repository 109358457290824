import { Typography, Alert } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';

function Phone({
	checked,
	testData,
	setForm,
	setPhoneTouched,
	phoneTouched,
	setPhoneVerified,
	phoneVerified,
	form
}) {
	const handlePhoneChange = (value) => {
		if (value.length === 17) {
			setPhoneVerified(true);
		} else {
			setPhoneVerified(false);
		}
		setForm((prevValue) => {
			return { ...prevValue, phone: value };
		});
	};

	const handlePhoneTouch = () => {
		setPhoneTouched(true);
	};
	return (
		<>
			<Typography
				variant='body1'
				style={{ marginTop: '1em' }}
			>
				Primary Caregiver's Phone Number*
			</Typography>
			<MuiPhoneNumber
				fullWidth
				required
				type='tel'
				style={{ marginTop: '1em' }}
				id='phone'
				name='phone'
				defaultCountry={'us'}
				disableDropdown={true}
				variant='outlined'
				onBlur={handlePhoneTouch}
				onChange={handlePhoneChange}
				value={checked ? testData.phone : form.phone}
			/>
			{!phoneVerified && phoneTouched && (
				<Alert
					sx={{ mt: 2 }}
					severity='error'
				>
					A valid phone number is required
				</Alert>
			)}
		</>
	);
}

export default Phone;
