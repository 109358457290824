import React from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";

export default function Mobile({ handleClick, anchorEl, handleClose, collapse, currentUser }) {
  return (
    <>
      <IconButton
        size="large"
        color="inherit"
        onClick={handleClick}
        aria-controls="simple-menu"
        aria-haspopup="true"
        sx={{ mr: 2 }}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {collapse && currentUser && currentUser.access === "caregiver" && (
          <MenuItem onClick={handleClose}>
            <Link className="mobileLink" to="/family-dashboard">
              My Dashboard
            </Link>
          </MenuItem>
        )}

        {collapse && currentUser && currentUser.access === "staff" && (
          <MenuItem onClick={handleClose}>
            <Link className="mobileLink" to="/applicationmanager">
              App Manager
            </Link>
          </MenuItem>
        )}

        <MenuItem onClick={handleClose}>
          <Link
            className="mobileLink"
            to={{ pathname: "https://www.thegatheringplacek12.org" }}
            target="_blank"
          >
            TGP's Website
          </Link>
        </MenuItem>
        {!currentUser && (
          <MenuItem onClick={handleClose}>
            <Link className="mobileLink" to="/login">
              Login
            </Link>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
