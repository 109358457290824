import { atom, selector } from 'recoil';
import {
	sortedLeadsGetter,
	filteredLeadNotesGetter,
	filteredAppsGetter,
	filterApplicationsByYearGetter,
} from './selectors';
import { LEAD_SORT_DIRECTION } from '../shared/libraries/objects';

//****** LEADS ATOMS ******//

export const leadsAtom = atom({
	key: 'leads',
	default: null,
});

export const leadSortFieldAtom = atom({
	key: 'leadSortField',
	default: 'submittedOn',
});

export const leadSortDirectionAtom = atom({
	key: 'leadSortDirection',
	default: LEAD_SORT_DIRECTION,
});

export const leadFilteredStatusesAtom = atom({
	key: 'leadFilteredStatuses',
	default: ['active', 'newLead', 'scheduledTour'],
});

export const leadFilteredStaffAtom = atom({
	key: 'leadFilteredStaff',
	default: [],
});

export const leadFilteredSearchAtom = atom({
	key: 'leadFilteredSearch',
	default: '',
});

export const sortedLeadsAtom = selector({
	key: 'sortedLeads',
	get: sortedLeadsGetter,
});

export const leadsResetAtom = atom({
	key: 'leadsReset',
	default: false,
});

export const selectedLeadAtom = atom({
	key: 'selectedLead',
	default: null,
});

export const leadNotesAtom = atom({
	key: 'leadNotes',
	default: null,
});

export const filteredLeadNotesAtom = selector({
	key: 'filteredLeadNotes',
	get: filteredLeadNotesGetter,
});

export const leadNotesResetAtom = atom({
	key: 'leadNotesReset',
	default: false,
});

export const leadStatusesAtom = atom({
	key: 'leadStatuses',
	default: null,
});

//****** STAFF ATOMS ******//

export const staffAtom = atom({
	key: 'staff',
	default: null,
});

export const staffObjAtom = atom({
	key: 'staffObj',
	default: null,
});

export const staffResetAtom = atom({
	key: 'staffReset',
	default: false,
});

export const currentStaffAtom = atom({
	key: 'currentStaff',
	default: null,
});

export const staffFromAPIAtom = atom({
	key: 'staffFromAPI',
	default: null,
});

export const permissionsObjAtom = atom({
	key: 'permissionsObj',
	default: null,
});

export const currentUserAtom = atom({
	key: 'currentUser',
	default: null,
});

//****** APPLICATION ATOMS ******//

export const appsAtom = atom({
	key: 'apps',
	default: null,
});

export const filteredAppsAtom = selector({
	key: 'filteredApps',
	get: filteredAppsGetter,
});

export const filteredAppStatusIdsAtom = atom({
	key: 'filteredAppStatusIds',
	default: [],
});

export const appsResetAtom = atom({
	key: 'appsReset',
	default: null,
});

export const selectedAppAtom = atom({
	key: 'selectedAppAtom',
	default: null,
});

export const editFieldDataAtom = atom({
	key: 'editFieldData',
	default: null,
});

export const editAppFieldOpenAtom = atom({
	key: 'editAppFieldOpen',
	default: false,
});

//****** APPLICATION STATUSES ATOMS ******//

export const appStatusesAtom = atom({
	key: 'applicationStatuses',
	default: null,
});

//****** YEAR FILTER ATOMS ******//

export const applicationYearStatusAtom = atom({
	key: 'applicationYearStatus',
	default: 'all',
});

export const filteredAppsByYearAtom = selector({
	key: 'filteredAppsByYear',
	get: filterApplicationsByYearGetter,
});
//****** REFERRAL ATOMS ******//

export const sourcesStateAtom = atom({
	key: 'sourcesState',
	default: null,
});

export const selectedSourceAtom = atom({
	key: 'selectedSource',
	default: null,
});

export const sourceResetAtom = atom({
	key: 'sourceReset',
	default: false,
});

//****** BULK ASSIGNMENT ATOMS ******//

export const bulkAssignCounterAtom = atom({
	key: 'bulkAssignCounter',
	default: 0,
});
