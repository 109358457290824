import { db } from "../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { COLLECTIONS } from "./objectsAndArrays";
import { DateTime } from "luxon";

export const validateEmail = async (email) => {
  if (!email.includes("@") || !email.includes(".")) {
    return false;
  }

  const caregiverRef = collection(db, COLLECTIONS.caregivers);
  const q = query(caregiverRef, where("email", "==", email));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.size > 0) {
    return true;
  } else {
    return false;
  }
};

export const birthdayCheck = (e) => {
  const maxDiffInDays = 2555;
  const year = e.substring(0, 4);
  const birthDate = new Date(e).getTime();
  const currentYear = new Date().getFullYear();
  const cutOffBirthDate = new Date(`${currentYear - 4}-09-01`);
  const diffInDays = (cutOffBirthDate - birthDate) / (1000 * 60 * 60 * 24);
  if (diffInDays < 0) {
    return "You Must be 5 by September 1st 2023 to Apply";
  }
  return null;
};

export const validateLoginEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const filterByValue = (array, string) => {
  return array.filter((o) =>
    Object.keys(o).some((k) => {
      if (k === "childFirstName" || k === "childLastName" || k === "phone") {
        return o[k].toLowerCase().includes(string.toLowerCase());
      } else {
        return false;
      }
    })
  );
};

export const getAppCardStatusStyle = (status) => {
  const styleObj = { color: "white", borderRadius: "4px", padding: "4px" };
  if (status === "Pending") {
    styleObj.backgroundColor = "grey";
  } else if (status === "Accepted Seat") {
    styleObj.backgroundColor = "green";
  } else if (status === "Waitlisted") {
    styleObj.backgroundColor = "orange";
  } else if (status === "Offered a Seat") {
    styleObj.backgroundColor = "yellow";
  } else if (status === "Partially Registered") {
    styleObj.backgroundColor = "purple";
  } else if (status === ("Fully Registered for SY 22 - 23" || "Active")) {
    styleObj.backgroundColor = "blue";
  } else if (
    status ===
    ("Caregiver Turned Seat Down" || "Ineligible Due to Address" || "Ineligible Due to Birthday")
  ) {
    styleObj.backgroundColor = "red";
  } else {
    styleObj.color = "rgba(0, 0, 0, 0.87)";
    styleObj.border = "0.1rem solid grey";
  }
  return styleObj;
};

export const getSiblings = ({ apps, data }) => {
  const siblingArray = [];
  apps.forEach((siblingCheckApp) => {
    if (siblingCheckApp.caregiverId === data.caregiverId && siblingCheckApp.id !== data.id) {
      siblingArray.push(siblingCheckApp);
    }
  });
  return siblingArray;
};

export const renderDate = (comment) => {
  let dt = null;

  if (comment.timestamp) {
    dt = DateTime.fromMillis(comment.timestamp.toMillis());
  }
  return dt
    ? dt.toLocaleString({
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : "No Date";
};

export const getNewLastCommentDate = (removedId, comments) => {
  if (comments.length < 2) {
    return null;
  } else {
    const removedIndex = comments.findIndex((comment) => comment.id === removedId);
    comments.splice(removedIndex, 1);
    if (comments.length > 0) {
      return comments[0].timestamp;
    } else {
      return null;
    }
  }
};

export const formatBirthday = (date) => {
  return new Date(`${date}T00:00:00`).toLocaleDateString();
};
