import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { staffFromAPIAtom } from "../recoil/atoms";

const useBootstrapAPIEffect = () => {
  const setStaffFromAPI = useSetRecoilState(staffFromAPIAtom);

  useEffect(() => {
    const getStaff = async () => {
      const response = await fetch(
        "https://us-central1-tgp-core-api.cloudfunctions.net/app/api/staff",
        {
          headers: {
            authorization: process.env.REACT_APP_FIREBASE_API_KEY,
          },
        }
      );
      const jsonStaff = await response.json();
      setStaffFromAPI(jsonStaff);
    };
    getStaff();
  }, [setStaffFromAPI]);
};

export default useBootstrapAPIEffect;
