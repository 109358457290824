import React from "react";
import { Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import NavBar from "../shared/Navigation/Navbar";
import SettingsContainer from "../components/Settings/SettingsContainer";


const SettingsPage = () => {
  return (
    <>
      <CssBaseline />
      <NavBar></NavBar>
      <Container sx={{ minHeight: "100vh", mt: 8, pt: 2 }} maxWidth={"xl"}>
        <SettingsContainer/>
      </Container>
    </>
  );
};

export default SettingsPage;
